import { getCookie } from '../../libs/cookies';
import { DEBUG, ERROR } from '../../libs/utils'
import { PACKAGE } from '../actions_const'
import { businessAPI, privateAPI } from '../end_point'

import axios from './../axios_request';

export const getPackages = (call_back) => {
  const TAG = 'getPackages'
  return dispatch => {
    axios({
      method: 'get',
      url: `${privateAPI}/package`,
      headers: { Authorization: getCookie("_token") }

    }).then(({ data }) => {
      DEBUG('THEN', TAG)
      if (typeof call_back === 'function') call_back(null, data)
      return dispatch({ type: PACKAGE.GET_PACKAGES, data: data })

    }).catch(err => {
      ERROR('CATCH', TAG)
      if (typeof call_back === 'function') call_back(err)
    })
  }
}

export const getBusinessPackage = ({ business_code, load = true }, call_back) => {
  return dispatch => {
    if (load) dispatch({ type: PACKAGE.PACKAGE_IS_PROCESS })
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/package`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      DEBUG('THEN getBusinessPackage', business_code)
      if (typeof call_back === 'function') call_back(null, data)
      return dispatch({ type: PACKAGE.GET_BUSINESS_PACKAGE, data: data })
    }).catch(err => {
      ERROR('CATCH getBusinessPackage', business_code, err.message)
      if (typeof call_back === 'function') call_back(err)
      //return dispatch({ type: PACKAGE.GET_BUSINESS_PACKAGE, data: {} })
    })
  }
}

export const getBusinessSubscription = (business_code, call_back) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/subscription`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      if (data.result) {
        if (typeof call_back === 'function') call_back(null, data)
        return dispatch({ type: PACKAGE.GET_BUSINESS_SUBSCRIPTION, data: data.data })
      } else {
        if (typeof call_back === 'function') call_back(data.error)
        return dispatch({ type: PACKAGE.GET_BUSINESS_SUBSCRIPTION, data: {} })
      }
    }).catch(err => {
      if (typeof call_back === 'function') call_back(err)
      return dispatch({ type: PACKAGE.GET_BUSINESS_SUBSCRIPTION, data: {} })
    })
  }
}

export const createBusinessSubscription = (body, call_back) => {
  return dispatch => {
    dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: true })
    axios({
      method: 'post',
      url: `${businessAPI}/${body.business_code}/subscription`,
      headers: { Authorization: getCookie("_token") },
      data: body
    }).then(({ data }) => {
      if (data.result) {
        if (typeof call_back === 'function') call_back(null, data.data)
        return dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: false })
      } else {
        return Promise.reject('result error')
      }
    }).catch(err => {
      if (typeof call_back === 'function') call_back(err)
      return dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: false })
    })
  }
}

export const cancelBusinessSubscription = (business_code, call_back) => {
  return dispatch => {
    //dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: true })
    axios({
      method: 'delete',
      url: `${businessAPI}/${business_code}/subscription`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      if (data.result) {
        if (typeof call_back === 'function') call_back(null, data.data)
        //return dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: false })
      } else {
        return Promise.reject('result error')
      }
    }).catch(err => {
      if (typeof call_back === 'function') call_back(err)
      //return dispatch({ type: PACKAGE.PACKAGE_IS_LOADING, loading: false })
    })
  }
}

export const createBusinessAddonSubscription = ({ business_code, subscription_id, addon }, call_back) => {
  return dispatch => {
    axios({
      method: 'POST',
      url: `${businessAPI}/${business_code}/subscription/addon`,
      headers: { Authorization: getCookie("_token") },
      data: { subscription_id, addon }
    }).then(({ data }) => {
      if (data.result && (typeof call_back === 'function'))
        call_back(null, data)
      else
        return Promise.reject('result error')
    }).catch(err => {
      if (typeof call_back === 'function') call_back(err)
    })
  }
}