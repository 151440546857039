import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toCurrency } from "../../libs/currency";
import { InvertBtn, SuccessBtn } from "../Button";
import { Title2, Title3 } from "../Font";

export default function CreditsUseModal(props) {
	const {
		isOpen,
		isModal,
		isMultiple = false,
		isImport = false,
		amount,
		userInfo,
		preUser,
		receiptAmount,
		sendNoti,
		toggleOpenCredit,
		confirmCreditsUse,
		dataId,
		dataMethod,
		callBack,
		setCredit,
		title,
	} = props;
	const dictionary = useSelector((state) => state.language.dictionary);
	const { packages } = useSelector((state) => state.packages);
	const { usage_record } = useSelector((state) => state.usage);
	const package_items = packages.package_data;
	let useCredits = 0;
	let showAlert = false;
	let disabled = "";
	if ((userInfo && userInfo.profile) || preUser) {
		const package_items_trans = package_items.items.find((item) => item.name === "additional_transaction");
		const trans_price = (package_items_trans && package_items_trans.pricing) || 0;
		let profile = userInfo.profile || [];
		let provider = "";
		let blockNoti = profile.block_noti_business || false;
		if (profile.country_code !== "+66") {
			if (profile.subscribe_date) provider = "email";
			else provider = "sms_inter";
		} else if (profile.line_id) {
			provider = "line_noti";
		} else if (profile.provider && profile.provider.shappy) {
			provider = "shappy";
		} else {
			provider = "sms";
		}
		const package_items_noti = package_items.items.find((item) => item.name === `additional_${provider}`);
		const noti_price = (package_items_noti && package_items_noti.pricing) || 0;
		useCredits = (parseFloat(trans_price) * amount + parseFloat(sendNoti && !blockNoti ? noti_price : 0)).toFixed(2);
		if (amount > usage_record.tx_remain && !usage_record.isOverTrans) {
			useCredits = (parseFloat(trans_price) * (amount - usage_record.tx_remain)).toFixed(2);
		}
		if (isMultiple || receiptAmount > 1) {
			let multiple = receiptAmount + (isMultiple ? 1 : 0);
			useCredits = (useCredits * multiple).toFixed(2);
		}
	} else if (isImport) {
		const package_items_trans = package_items.items.find((item) => item.name === "additional_transaction");
		const trans_price = (package_items_trans && package_items_trans.pricing) || 0;
		let package_items_noti;
		if (package_items.package_id === 'ps-f200') {
			package_items_noti = package_items.items.find((item) => item.name === `additional_line_noti`);
		} else {
			package_items_noti = package_items.items.find((item) => item.name === `additional_sms`);
		}
		const noti_price = (package_items_noti && package_items_noti.pricing) || 0;
		useCredits = ((parseFloat(trans_price) + parseFloat(noti_price)) * amount).toFixed(2);
	}
	if (usage_record.credit_remaining - useCredits < 0) {
		if (!isModal) callBack();

		showAlert = true;
		disabled = "disabled";
	}

	useEffect(() => {
		if (typeof setCredit === "function") {
			setCredit(useCredits);
		}
	}, [useCredits]); // eslint-disable-line

	return (
		<>
			{isModal ? (
				<Modal isOpen={isOpen} centered>
					<ModalHeader>
						<Title2 bold>{title}</Title2>
					</ModalHeader>
					<ModalBody>
						<div className='w-100 d-flex flex-column justify-content-center' style={{ minHeight: 150 }}>
							<Title2 className='mb-3 text-left' bold>
								{dictionary.broadcast_billing_credit}
							</Title2>
							<div className='align-items-baseline d-flex w-100 mb-2'>
								<Title3 className='w-100 text-left' bold style={{ paddingLeft: "15px" }}>
									{dictionary.broadcast_billing_amount}
								</Title3>
								<Title3 className='w-100 text-right' bold>
									{dictionary.credit}
								</Title3>
							</div>
							<div className='align-items-baseline d-flex w-100 mb-4'>
								<Title2 className='w-100 text-left' style={{ paddingLeft: "15px" }}>
									{amount || 0}
								</Title2>
								<Title2 className='w-100 text-right'>{toCurrency(useCredits, 2)}</Title2>
							</div>
							{showAlert && (
								<Title3 danger className='pt-2 pb-2 text-right'>
									{dictionary.credit_not_enough}
								</Title3>
							)}
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='w-100 d-flex align-items-center justify-content-end'>
							<InvertBtn md bold onClick={toggleOpenCredit}>
								{dictionary.cancel}
							</InvertBtn>
							<div className='pl-2' />
							{isImport ? (
								<SuccessBtn
									md
									bold
									data-id={dataId}
									data-method={dataMethod}
									onClick={confirmCreditsUse}
									disabled={disabled}
								>
									{dictionary.submit}
								</SuccessBtn>
							) : (
								<SuccessBtn md bold onClick={confirmCreditsUse} disabled={disabled}>
									{dictionary.submit}
								</SuccessBtn>
							)}
						</div>
					</ModalFooter>
				</Modal>
			) : (
				<>
					<div className='d-flex justify-content-end align-items-end pt-4'>
						<Title3>{dictionary.credit_to_be_used}</Title3>
						<Title2>
							&nbsp;{toCurrency(useCredits, 2)} {dictionary.credit}
						</Title2>
					</div>
					{showAlert && (
						<div className='d-flex justify-content-end align-items-end pt-2'>
							<Title3 danger>{dictionary.credit_not_enough}</Title3>
						</div>
					)}
				</>
			)}
		</>
	);
}
