import { MESSAGE } from '../actions_const'


let initState = {
  broadcast_message: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case MESSAGE.FETCH_BROADCAST_MESSAGE:
      return Object.assign({}, state, {
        broadcast_message: action.data
      })
    default:
      return state
  }
}