import {
	BUSINESS_SEND_POINT_SUCCESS,
	CLEAR_BUSINESS_REVIEW,
	CREATE_BUSINESS,
	CREATE_BUSINESS_PINCODE,
	CREATE_VERIFYBUSINESS_PROCESS,
	END_BUSINESS_PROCESS,
	GET_BUSINESS_BALANCE,
	GET_BUSINESS_INFO_FAIL,
	GET_BUSINESS_INFO_SUCCESS,
	GET_BUSINESS_LABEL,
	GET_BUSINESS_LIST_FAIL,
	GET_BUSINESS_LIST_SUCCESS,
	GET_BUSINESS_LINE_MEMBERS,
	GET_BUSINESS_SHAPPY_MEMBERS,
	GET_BUSINESS_INTER_MEMBERS,
	GET_BUSINESS_PINCODE,
	GET_BUSINESS_POINTEXPIRY,
	GET_BUSINESS_REVIEW,
	GET_BUSINESS_REVIEW_MESSAGE,
	GET_BUSINESS_REVIEW_PROCESS,
	GET_MEMBER_CARD_INFO,
	GET_POLICY_BUSINESS,
	GET_TERMS_BUSINESS,
	GET_VERIFYBUSINESS,
	GET_VERIFYBUSINESS_PROCESS,
	MANAGER,
	RELOAD_PAGE,
	SET_BUSINESS_DROPDOWNLIST,
	START_BUSINESS_EXPIRY_POINTS_PROCESS,
	START_BUSINESS_PROCESS,
	TOGGLE_CREATE_BUSINESS_MODAL,
	UPDATE_BUSINESS,
	UPDATE_BUSINESS_CONFIG,
	UPDATE_BUSINESS_CONTACT_BRANCH_PRIMARY,
	UPDATE_LINE_LIFF,
	UPDATE_VERIFYBUSINESS,
	UPDATE_VERIFYBUSINESS_DETAIL,
	VERIFYBUSINESS_PROCESS,
	PERMISSION,
	IS_CREATE_BUSINESS,
	IS_CREATED_BUSINESS,
	VERIFY_EMAIL_OTP,
	PERMISSION_DENIED,
} from "../actions_const";

import { getCookie } from "../../libs/cookies";
import { DEBUG } from "../../libs/utils";

let initState = {
	isError: false,
	isWallet: false,
	isFetching: true,
	isLoading: true,
	isLoading2: false,
	isFetching2: false,
	isProcess: false,
	isLoadingExpiry: false,
	isLoadingReview: false,
	isCreated: false,
	toggleModal: false,
	current: {},
	balance: { total: 0, earn: {}, redeem: {}, transfer: {}, pull: {}, pay: {} },
	permission: {},
	list: [],
	terms: {},
	verifyBusiness: {},
	reviews: {
		loadmore: true,
		limit: 12,
		offset: 0,
		total: {},
		reviews_rate: [],
		reviews_message: [],
	},
	pincode: {
		offset: 0,
		limit: 20,
		total: 0,
		data: [],
	},
	member_card_info: undefined,
	label: { transaction_label: [], member_label: [] },
	business_type: [],
	isFromDropdown: false,
};

export default (state = initState, action) => {
	switch (action.type) {
		case PERMISSION.USER_LOGOUT_SUCCESS:
			return initState;
		case GET_BUSINESS_LIST_SUCCESS:
			let __bnc = getCookie("__bnc");
			__bnc = action.data.filter((v) => v.business_code === __bnc);
			DEBUG("[GET_BUSINESS_LIST_SUCCESS]", getCookie("__bnc"), __bnc);
			return Object.assign({}, state, {
				isError: false,
				isLoading: false,
				list: action.data,
				current: __bnc[0] || action.data[0],
			});
		case GET_BUSINESS_LINE_MEMBERS: {
			return Object.assign({}, state, {
				line_members: action.data,
			});
		}
		case GET_BUSINESS_SHAPPY_MEMBERS: {
			return Object.assign({}, state, {
				shappy_members: action.data,
			});
		}
		case GET_BUSINESS_INTER_MEMBERS: {
			return Object.assign({}, state, {
				inter_members: action.data,
			});
		}
		case GET_BUSINESS_LIST_FAIL:
			return Object.assign({}, state, {
				isError: true,
				isLoading: false,
				current: {},
				permission: {},
				list: [],
			});

		case SET_BUSINESS_DROPDOWNLIST:
			let current_business = state.list.filter((v) => v.business_code === action.businessCode);
			return Object.assign({}, state, {
				isFetching: true,
				current: current_business[0],
				isFromDropdown: true,
			});

		case GET_BUSINESS_INFO_SUCCESS:
			if (action.changeTab) {
				return Object.assign({}, state, {
					isLoading: false,
					isFetching: false,
					isError: false,
					current: Object.assign({}, state.current, action.data),
					permission: action.data.permission,
				});
			} else {
				return Object.assign({}, state, {
					isLoading: false,
					isFetching: false,
					isError: false,
					current: Object.assign({}, state.current, action.data),
					permission: action.data.permission,
					balance: { total: 0, earn: {}, redeem: {}, transfer: {}, pull: {}, pay: {} },
					label: { transaction_label: [], member_label: [] },
					member_card_info: undefined,
				});
			}

		case GET_BUSINESS_INFO_FAIL:
			return Object.assign({}, state, {
				isLoading: false,
				isFetching: false,
				isError: true,
			});

		case GET_BUSINESS_BALANCE: {
			return Object.assign({}, state, {
				isWallet: state.isWallet || action.status,
				balance: Object.assign({}, state.balance, action.balance || {}),
			});
		}

		case CREATE_BUSINESS:
			return Object.assign({}, state, {
				isProcess: false,
				list: [...state.list, action.data],
				current: action.data,
				toggleModal: false,
			});

		case IS_CREATE_BUSINESS:
			return Object.assign({}, state, { isCreated: true });

		case IS_CREATED_BUSINESS:
			return Object.assign({}, state, { isCreated: false });

		case UPDATE_BUSINESS:
			let index = state.list.findIndex((e) => e.business_code === action.data.business_code);
			state.list[index] = Object.assign({}, state.list[index], action.data);
			return Object.assign({}, state, {
				isProcess: false,
				current: Object.assign({}, state.current, action.data),
			});

		case START_BUSINESS_PROCESS:
			return Object.assign({}, state, {
				isProcess: true,
			});

		case END_BUSINESS_PROCESS:
			return Object.assign({}, state, {
				isProcess: false,
			});

		case TOGGLE_CREATE_BUSINESS_MODAL:
			return Object.assign({}, state, {
				toggleModal: !state.toggleModal,
			});

		case RELOAD_PAGE:
			return Object.assign({}, state, {
				isFetching: action.data,
			});

		case GET_TERMS_BUSINESS:
			Object.assign(state.current, state, {
				terms_config: action.data.terms_config,
				terms_config_publish: action.data.terms_config_publish,
			});
			return Object.assign({}, state, {
				isFetching: false,
			});

		case GET_POLICY_BUSINESS:
			Object.assign(state.current, state, {
				policy_config: action.data.policy_config,
				policy_config_publish: action.data.policy_config_publish,
				policy_config_url: action.data.policy_config_url,
				policy_config_option: action.data.policy_config_option,
			});

			return Object.assign({}, state, {
				isFetching: false,
			});

		case GET_VERIFYBUSINESS_PROCESS:
			return Object.assign({}, state, {
				isLoading2: true,
			});

		case GET_VERIFYBUSINESS:
			return Object.assign({}, state, {
				isLoading2: false,
				verifyBusiness: action.data,
			});
		case UPDATE_VERIFYBUSINESS_DETAIL:
			return Object.assign({}, state, {
				verifyBusiness: Object.assign({}, state.verifyBusiness, action.data || {}),
				current: Object.assign({}, state.current, {
					verified: (action.data && action.data.status === "verified") || state.current.verified,
				}),
			});

		case CREATE_VERIFYBUSINESS_PROCESS:
			return Object.assign({}, state, {
				isLoading2: true,
				isFetching2: true,
			});

		case VERIFYBUSINESS_PROCESS:
			return Object.assign({}, state, {
				isLoading2: false,
				isFetching2: false,
				verifyBusiness: action.data,
			});

		case UPDATE_VERIFYBUSINESS:
			return Object.assign({}, state, {
				isProcess: false,
				verifyBusiness: Object.assign({}, state.verifyBusiness, action.data),
			});

		case BUSINESS_SEND_POINT_SUCCESS:
			state.current.total_send_points++;
			return state;

		case UPDATE_BUSINESS_CONTACT_BRANCH_PRIMARY:
			const index_branch = state.current.business_branch.findIndex((obj) => obj.branch_type === "primary");
			state.current.business_branch[index_branch].branch_contacts = action.data.branch_contacts;
			return Object.assign({}, state, {
				isProcess: false,
			});

		case GET_BUSINESS_REVIEW_PROCESS:
			return Object.assign({}, state, {
				isProcess: true,
			});

		case GET_BUSINESS_REVIEW:
			let check_load =
				action.data && action.data.reviews_message && action.data.reviews_message.length === state.reviews.limit;
			return Object.assign({}, state, {
				isProcess: false,

				reviews: Object.assign({}, initState.reviews, action.data, { loadmore: check_load }),
			});

		case GET_BUSINESS_REVIEW_MESSAGE:
			let check_load2 =
				action.data && action.data.reviews_message && action.data.reviews_message.length === state.reviews.limit;
			let reviews_message = [...state.reviews.reviews_message, ...(action.data && action.data.reviews_message)];
			return Object.assign({}, state, {
				isProcess: false,
				reviews: Object.assign(
					{},
					state.reviews,
					{ reviews_message },
					{ offset: action.data.offset },
					{ loadmore: check_load2 }
				),
			});

		case CLEAR_BUSINESS_REVIEW:
			return Object.assign({}, state, {
				reviews: initState.reviews,
			});

		case START_BUSINESS_EXPIRY_POINTS_PROCESS:
			return Object.assign({}, state, {
				isLoadingExpiry: true,
			});

		case GET_BUSINESS_POINTEXPIRY:
			if (action.data)
				return Object.assign({}, state, {
					isLoadingExpiry: false,
					current: Object.assign({}, state.current, { expiry_point: action.data }),
				});
			else
				return Object.assign({}, state, {
					isLoadingExpiry: false,
				});

		case UPDATE_BUSINESS_CONFIG:
			return Object.assign({}, state, {
				current: Object.assign({}, state.current, action.data),
			});

		case GET_BUSINESS_PINCODE:
			return Object.assign({}, state, {
				pincode: Object.assign({}, state.pincode, {
					offset: Number.parseInt(action.offset, 10),
					total: Number.parseInt(action.total, 10),
					limit: Number.parseInt(action.limit, 10),
					data: action.data,
				}),
			});

		case GET_MEMBER_CARD_INFO:
			const memberCardInfo = action.data && action.data.length ? action.data : [];
			return Object.assign({}, state, {
				member_card_info: memberCardInfo,
			});

		case GET_BUSINESS_LABEL:
			let transaction_label =
				action.data && action.data.length ? action.data.filter((f) => f.tag_type === "transaction") : [];
			let member_label = action.data && action.data.length ? action.data.filter((f) => f.tag_type === "member") : [];
			return Object.assign({}, state, {
				label: Object.assign({}, state.label, {
					transaction_label: transaction_label.length
						? transaction_label
						: action.isDelete && action.isDelete === "transaction"
						? []
						: state.label.transaction_label || [],
					member_label: member_label.length
						? member_label
						: action.isDelete && action.isDelete === "member"
						? []
						: state.label.member_label || [],
				}),
			});

		case MANAGER.UPDATE_BUSINESS_NAME: {
			const { business_name } = action.data;
			return Object.assign({}, state, {
				verifyBusiness: {
					...state.verifyBusiness,
					business_name,
				},
			});
		}

		case CREATE_BUSINESS_PINCODE:
			return state;

		case UPDATE_LINE_LIFF:
			return Object.assign(state.current, state, {
				liff_id: action.data.liff_id,
			});

		case VERIFY_EMAIL_OTP.UPDATE_VERIFY_EMAIL_OTP_SUCCESS:
			return Object.assign({}, state, {
				list: action.data,
			});

		case PERMISSION_DENIED:
			return Object.assign({}, state, {
				isError: true
			});

		default:
			return state;
	}
};
