import { MASTER_DATA } from '../actions_const'

let initState = {
  archive_reasons: []
}

export default (state = initState, action) => {
  switch (action.type) {

    case MASTER_DATA.GET_ARCHIVE_REASONS:
      return Object.assign({}, state, {
        archive_reasons: action.data,
      })

    default:
      return state
  }
}