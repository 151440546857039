import _ from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Title2, Title3 } from "../../../../components/Font";
import { toCurrency } from "../../../../libs/currency";
import prepareText from '../../../../libs/prepareText';
import { nl2br } from '../../../../libs/nl2br';

export default function EcardBillingInfo({ sendSms, callBack }){
  const isMounted = useRef(false);
  const [billingInfo, setBillingInfo] = useState({});
  const [disable, setDisable] = useState(false)
  const dictionary = useSelector((state) => state.language.dictionary);
  const broadcastTarget = useSelector((state) => state.broadcast.broadcast_target)
  const currentBusiness = useSelector((state) => state.business.current);
  const { packages, subscription: { isNewPackage } } = useSelector((state) => state.packages)
  const { usage_record } = useSelector((state) => state.usage)
  const { business_code, is_use_line_messaging, shappy_setting, is_use_credit} = currentBusiness;
  const package_items = packages.package_data
  const current_package = packages?.package_data?.package_id;
  const packagePromoTx = !Boolean(isNewPackage)

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      let billing = {};
      const TRANS_PRICE = parseFloat(_.find(package_items.items, (item) => { return item.name === 'additional_transaction' })?.pricing) || 0.2;
      const SMS_PRICE = parseFloat(_.find(package_items.items, (item) => { return item.name === 'additional_sms' })?.pricing) || 0.4;
      const LINE_PRICE = parseFloat(_.find(package_items.items, (item) => { return item.name === 'additional_line_noti' })?.pricing) || 0.1;
      const SHAPPY_PRICE = 0.0;
      const EMAIL_PRICE = parseFloat(_.find(package_items.items, (item) => { return item.name === 'additional_email' })?.pricing) || 0.1;

      billing.total = broadcastTarget.total

      let addTransPrice = sendSms ? parseFloat(TRANS_PRICE) : 0;
      let lineTotal = 0
      let shappyTotal = 0
      let interTotal = 0
      if (is_use_line_messaging) lineTotal = broadcastTarget.line_total
      if (shappy_setting) shappyTotal = broadcastTarget.shappy_total
      interTotal = broadcastTarget.inter_total
      billing.sms = broadcastTarget.total - lineTotal - shappyTotal - interTotal
      billing.line = lineTotal
      billing.shappy = shappyTotal
      billing.email = interTotal
      if (current_package === 'ps-f200') {
        billing.sms = 0
        billing.total = lineTotal + shappyTotal + interTotal
      }
      billing.price_sms = (billing.sms * SMS_PRICE) + (billing.sms * addTransPrice)
      billing.price_line = (billing.line * LINE_PRICE) + (billing.line * addTransPrice)
      billing.price_shappy = (billing.shappy * SHAPPY_PRICE) + (billing.shappy * addTransPrice)
      billing.price_email = (billing.email * EMAIL_PRICE) + (billing.email * addTransPrice)
      billing.price_total = (billing.price_sms + billing.price_line + billing.price_shappy + billing.price_email).toFixed(2)
      billing.price_trans_total = (broadcastTarget.total * TRANS_PRICE).toFixed(2)
      if (!packagePromoTx && usage_record?.tx_remain) {
        let tx_remaining = usage_record?.tx_remain;
        billing.price_sms = billing.price_sms - (Math.min(tx_remaining, billing.sms) * (SMS_PRICE + addTransPrice))
        tx_remaining = Math.max(tx_remaining - billing.sms, 0)
        billing.price_line = billing.price_line - (Math.min(tx_remaining, billing.line) * (LINE_PRICE + addTransPrice))
        tx_remaining = Math.max(tx_remaining - billing.line, 0)
        billing.price_email = billing.price_email - (Math.min(tx_remaining, billing.email) * (EMAIL_PRICE + addTransPrice))
        billing.price_total = (billing.price_sms + billing.price_line + billing.price_email).toFixed(2)
        billing.price_trans_total = (Math.max(broadcastTarget.total - usage_record?.tx_remain, 0) * TRANS_PRICE).toFixed(2)
      }
      setBillingInfo(billing)
      if (is_use_credit) {
        let credit = sendSms ? billing.price_total : billing.price_trans_total;
        let checkCredit = usage_record.credit_remaining - credit < 0
        setDisable(checkCredit)
        callBack({ credit, disable: checkCredit })
      }
    }
  }, [business_code, package_items, broadcastTarget, is_use_line_messaging, shappy_setting, sendSms, usage_record]);// eslint-disable-line

  const showSMS = billingInfo.sms > 0
  const showLine = billingInfo.line > 0
  const showShappy = billingInfo.shappy > 0
  const showEmail = billingInfo.email > 0
  const showSubtotal = (usage_record.isOverTrans && !packagePromoTx) || (usage_record.tx_remain < billingInfo.total && !packagePromoTx) || packagePromoTx || (!is_use_credit)

  if (!showSubtotal && is_use_credit) {
    const style = document.getElementById("billingInfoStyle")
    if (style) style.style.maxWidth = "370px"
  } else {
    const style = document.getElementById("billingInfoStyle")
    if (style) style.style.maxWidth = ""
  }

  return (
    <div className="w-100 text-left">
      {
          broadcastTarget.total <= 0 
          ? <Title2 className="mt-3 mb-4" bold center>{dictionary.not_member_in_target_group}</Title2>
          : <>
              {showSubtotal && <Title2 className="mb-3" bold>{dictionary.broadcast_billing_credit}</Title2>}
              {
                sendSms ?
                <>
                  <div className="align-items-baseline d-flex w-100 mb-2">
                    <Title3 className="w-25" bold>{dictionary.broadcast_billing_provider}</Title3>
                    <Title3 className="w-100 text-right" bold>{dictionary.broadcast_billing_amount}</Title3>
                    {showSubtotal && <Title3 className="w-100 text-right" bold>{dictionary.credit}</Title3>}
                  </div>
                  {
                    showSMS
                    && <div className={`align-items-baseline d-flex w-100 mb-2 ${showSubtotal ? '' : 'pl-2'}`}>
                      <Title2 className="w-25">SMS</Title2>
                      <Title2 className="w-100 text-right">{billingInfo.sms ? billingInfo.sms : 0}</Title2>
                      {showSubtotal && <Title2 className="w-100 text-right">{toCurrency(billingInfo.price_sms, 2)}</Title2>}
                    </div>
                  }
                  {
                    showLine
                    && <div className={`align-items-baseline d-flex w-100 mb-2 ${showSubtotal ? '' : 'pl-2'}`}>
                      <Title2 className="w-25">LINE</Title2>
                      <Title2 className="w-100 text-right">{billingInfo.line ? billingInfo.line : 0}</Title2>
                      {showSubtotal && <Title2 className="w-100 text-right">{toCurrency(billingInfo.price_line, 2)}</Title2>}
                    </div>
                  }
                  {
                    showEmail
                    && <div className={`align-items-baseline d-flex w-100 mb-2 ${showSubtotal ? '' : 'pl-2'}`}>
                      <Title2 className="w-25">{dictionary.email}</Title2>
                      <Title2 className="w-100 text-right">{billingInfo.email ? billingInfo.email : 0}</Title2>
                      {showSubtotal && <Title2 className="w-100 text-right">{toCurrency(billingInfo.price_email, 2)}</Title2>}
                    </div>
                  }
                  {
                    showShappy
                    && <div className={`align-items-baseline d-flex w-100 mb-2 ${showSubtotal ? '' : 'pl-2'}`}>
                      <Title2 className="w-25">Shappy</Title2>
                      <Title2 className="w-100 text-right">{billingInfo.shappy ? billingInfo.shappy : 0}</Title2>
                      {showSubtotal && <Title2 className="w-100 text-right">{toCurrency(billingInfo.price_shappy, 2)}</Title2>}
                    </div>
                  }
                  <div className="align-items-baseline d-flex w-100 border-top mb-3 pt-2">
                    {
                      <>
                        <Title2 className="w-25" bold>{dictionary.broadcast_billing_total}</Title2>
                        <Title2 className="w-100 text-right" bold>{billingInfo.total || 0}</Title2>
                        {showSubtotal && <Title2 className="w-100 text-right" bold>{toCurrency(billingInfo.price_total, 2)}</Title2>}
                      </>
                    }
                  </div>
                </>
              : showSubtotal ?
                <>
                  <div className="align-items-baseline d-flex w-100 mb-2">
                    <Title3 className="w-100 text-left" bold style={{ paddingLeft: '15px' }}>{dictionary.broadcast_billing_amount}</Title3>
                    <Title3 className="w-100 text-right" bold>{dictionary.credit}</Title3>
                  </div>
                  <div className="align-items-baseline d-flex w-100 mb-4">
                    <Title2 className="w-100 text-left" style={{ paddingLeft: '15px' }}>{billingInfo.total || 0}</Title2>
                    <Title2 className="w-100 text-right">{toCurrency(billingInfo.price_trans_total, 2)}</Title2>
                  </div>
                </>
                : <div className='w-100 text-center'>
                    <Title2 bold>{nl2br(prepareText(dictionary.ecoupon_costs_approximately_transaction, [billingInfo.total || '0']))}</Title2>
                  </div>
              }
              {
                disable &&
                <Title3 danger className="pt-2 pb-2 text-right">{dictionary.credit_not_enough}</Title3>
              }
            </>
      }

    </div>
  )
}