import { Title1, Title2, Title3 } from '../Font'
import styled, { css } from 'styled-components'

export const ModalBodyBox = styled.div`
  width: 100%;
  max-height: calc(100vh - 120px);
  
  overflow: hidden;
  overflow-y: scroll;
`

export const BusinessPlanAll = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const BusinessPlanBox = styled.div`
  height: 100%;
  overflow: hidden;
  letter-spacing: 0.05rem;
  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;

  ${props => props.package_style
    ? css`background-color: ${props.package_style.background_color};`
    : css`background-color: #F7F7F7;`
  }
  
  box-shadow: 0 0 10px rgba(0,0,0,0.17);
  color: ${props => props.theme.primary};
`

export const BusinessPlanTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 0px 5px 0px;
  letter-spacing: 0.1rem;
  border-bottom: 1px solid ${props => props.package_style ? props.package_style.border : props.theme.border_line};
`

export const BusinessPlanSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const BusinessPlanDetail = styled.div`
  width: 90%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.package_style ? props.package_style.border : props.theme.border_line};
  padding: 10px 0px;
  :last-child{
    border-bottom 0px;
  }
`

export const PlanDetail = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;
`

export const BusinessPlanFeature = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0px;
  border-bottom: 1px solid ${props => props.package_style ? props.package_style.border : props.theme.border_line};
`

export const PlanFeature = styled.div`
  width: 100%;  
  display: flex;
  align-items: center;
`

export const PlanFeatureDisc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  text-align: left;
  justify-content: flex-start;
  padding-top: 0.625rem;

  &::before {
    content: "\\2022";
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-right: 0.5rem;
    line-height: 1rem;
    color: ${props => props.color || props.theme.primary};
  }
`

export const PlanFeatureGroup = styled.div`
  width: 100%;
`

export const PlanFeatureDetail = styled.div`
  width: 100%;
  margin-left: 30px;
`

export const BusinessPlanAddon = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0px;
`

export const UpgradePlan = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  border-top: 1px solid ${props => props.package_style ? props.package_style.border : props.theme.border_line};
`
export const UpgradePlanTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 0px 15px 0px;
  border-bottom: 1px solid ${props => props.package_style ? props.package_style.border : props.theme.border_line};
`

export const BusinessDetailChoose = styled.div`
  width: 100%;
  margin: 5px 8px 0px 8px;
  padding: 12px 8px;
  border-radius: 20px;
  -webkit-transition: 0.8s;
  transition: 0.8s;

  font-weight: ${props => props.theme.font_bold};
  font-size: ${props => props.theme.font_s};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_xs};
  }

  ${props => (props.current)
    ? css`
      color: ${props.theme.white};
      background-color: ${props.theme.green};
      border: 1px solid ${props.theme.green};
      
      &:hover{
        box-shadow: 0 0 8px ${props.theme.green};
      }
    `
    : css`
      cursor: pointer;
      color: #212529;
      background-color: ${props.theme.yellow};
      border: 1px solid ${props.theme.yellow};
      box-shadow: 0 0 8px ${props.theme.dark};
      
      &:hover{
        box-shadow: 0 0 8px ${props.theme.yellow};
      }
    `
  }
  
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PGTitle1 = styled(Title1)`
  color: ${props => props.color || props.theme.primary};
  font-size: 22px;
  @media (max-width: 990px) {font-size: 20px;}
`

export const PGTitle2 = styled(Title2)`
  color: ${props => props.color || props.theme.primary};
  font-size: 14px;
  @media (max-width: 990px) {font-size: 12px;}
`

export const PGTitle3 = styled(Title3)`
  color: ${props => props.color || props.theme.primary};
  font-size: 12px;
  @media (max-width: 990px) {font-size: 10px;}
`

export const RemarkBox = styled.div`
  padding: 0px 15px;
  margin: 0px 15px;
`

export const BoxModalBody = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;

  padding-right: 10px;
  max-height: calc(100vh - 300px);
`

export const DropdownCardBox = styled.div`
  position: absolute;
  top: 10px; 
  margin-left: 20px;
  min-width: 175px;
  background: #FFF;
  z-index: 1001;
  border-radius: 5px;
  border-color: rgba(0,0,0,.2);
  box-shadow: 0px 3px 6px #00000029;
`

export const DropdownCardBoxBottom = styled.div`
  position: absolute;
  top: 50px;
  right: 40px;
  margin-left: 20px;
  min-width: 175px;
  background: #FFF;
  z-index: 1001;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, .2);
  box-shadow: 0px 3px 6px #00000029;
`

export const IconCheckFeature = styled.i`
  color: ${props => props.package_style ? props.package_style.primary : props.theme.primary};
`

export const PackageLabel = styled.div`
  width: fit-content;
  text-align: center;
  font-size: ${props => props.theme.font_s};
  font-weight: bold;
  color: ${props => props.package_style ? props.package_style.primary : props.theme.primary};
  background-color: ${props => (props.package_style ? props.package_style.background_color : props.theme.primary + '25')};
  border: 2px solid ${props => props.package_style ? props.package_style.border : props.theme.primary};
  white-space: nowrap;
  padding: 3px 20px;
  border-radius: 3px;
  `

export const DangerDot = styled.span`
  height: 8px;
  width: 8px;
  background-color: ${props => props.theme.red};
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  `

export const PackageTitle = styled.h1`
  font-size: 4rem;
  font-weight: bold;
  padding-bottom: 0.35rem;
  font-variant-caps: all-small-caps;
  background-color: ${props => props.theme.primary};
  color: transparent;
  text-shadow: 0px 2px 3px rgba(255, 255, 255, 0.1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  filter: brightness(0.84);

  @media (max-width: 1200px) {
    font-size: 5rem;
  }

  @media (max-width: 992px) {
    font-size: 4rem;
  }

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 576px) {
    font-size: 2rem;
  }
`