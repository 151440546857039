import moment from 'moment'
import 'moment/locale/th'

import { getCookie, setCookie } from '../../libs/cookies'

import { SELECT_LANGUAGE } from '../actions_const'
import dictionary from '../../assets/lang'

const cookie_name = '__lang'
let defaultLocale = getCookie(cookie_name) || 'th'
// let defaultLocale = getCookie(cookie_name) || window.navigator.language.split('-')[0] || 'en'

if(!dictionary[defaultLocale]){
  defaultLocale = 'th'
  setCookie(cookie_name, defaultLocale)
}


let initState = {
  locale: defaultLocale,
  dictionary: dictionary[defaultLocale] || dictionary['th']
}

export default (state = initState, action) => {
  switch (action.type) {
    case SELECT_LANGUAGE:
      setCookie(cookie_name, action.data)
      moment.locale(action.data)
      return Object.assign({}, state, {
        locale: action.data,
        dictionary: dictionary[action.data]
      })
    default:
      return state
  }
}