import {
  GET_REWARD_LIST_START,
  GET_REWARD_LIST,
  GET_REWARD_LIST_MORE,
  GET_REWARD_LIST_END,
  CLEAR_REWARDS,
  GET_ALL_REWARD_LIST,
} from '../actions_const'

const { REACT_APP_COUPON_LIMIT } = process.env

let initState = {
  isLoading: false,
  isSaving: false,
  modal: false,
  formData: {},
  limit: parseInt(REACT_APP_COUPON_LIMIT),
  offset: 0,
  loadmore: false,
  RewardsList: [],
  AllRewardsList: [],
}

export default (state = initState, action) => {

  switch (action.type) {
    case GET_REWARD_LIST_START:
      return Object.assign({}, state, { isLoading: true })
    case GET_REWARD_LIST:
      let check_load = parseInt(initState.limit) === (action.data && action.data.data && action.data.data.length) && (action.total > (parseInt(initState.limit)) + Number(action.data.offset))
      return Object.assign({}, initState, {
        offset: action.data && Number(action.data.offset),
        isLoading: false,
        RewardsList: action.data && action.data.data,
        loadmore: check_load
      })
    case GET_REWARD_LIST_MORE:
      let data_loadmore = action.data && action.data.data
        ? [...state.RewardsList, ...action.data.data]
        : [...state.RewardsList]
      let check_load2 = parseInt(initState.limit) === (action.data && action.data.data && action.data.data.length) && (action.total > (parseInt(initState.limit)) + Number(action.data.offset))
      return Object.assign({}, state, {
        isLoading: false,
        RewardsList: data_loadmore,
        offset: action.data && Number(action.data.offset),
        loadmore: check_load2
      })
    case GET_REWARD_LIST_END:
      return Object.assign({}, state, {
        isLoading: false,
        RewardsList: initState.RewardsList,
      })
    case GET_ALL_REWARD_LIST:
      return Object.assign({}, state, {
        isLoading: false,
        AllRewardsList: (action && action.data) || [],
      })
    case CLEAR_REWARDS:
      return initState
    default:
      return state
  }
}

