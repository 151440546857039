import {
  GET_DETAILCOUPON,
  GET_UNMOUNT_COUPON,
  GET_LOADING_REDEEM_COUPON,
  ACTIVATED_REDEEM_CODE,
  GET_RECEIPT_COUPON,
  GET_RECEIPT_COUPON_MORE,
  GET_RECEIPT_COUPON_END,
  CREATE_RECEIPT_COUPON,
  DELETE_RECEIPT_COUPON,
  UPDATE_RECEIPT_COUPON,
  GET_GIVEAWAY_COUPON,
  GET_GIVEAWAY_COUPON_MORE,
  UPDATE_GIVEAWAY_COUPON,
  GET_GIFTVOUCHER_COUPON,
  GET_GIFTVOUCHER_COUPON_MORE,
  UPDATE_GIFTVOUCHER_COUPON,
  GET_ALL_GIVEAWAY_COUPON,
} from '../actions_const'

const { REACT_APP_COUPON_LIMIT } = process.env

const initState = {
  isLoading: false,
  isSearch: false,
  data: [],
  receiptCoupon: {
    limit: parseInt(REACT_APP_COUPON_LIMIT) || 9,
    offset: 0,
    total: 0,
    list: [],
    loadmore: false
  },
  giveawayCoupon: {
    limit: parseInt(REACT_APP_COUPON_LIMIT) || 9,
    offset: 0,
    list: [],
    loadmore: false
  },
  giftvoucher: {
    limit: parseInt(REACT_APP_COUPON_LIMIT) || 9,
    offset: 0,
    list: [],
    loadmore: false
  },
  allGiveawayCoupon: {
    list: []
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_DETAILCOUPON:
      return Object.assign({}, state, {
        isLoading: false,
        isSearch: true,
        data: (action && action.data) || []
      })

    case GET_LOADING_REDEEM_COUPON:
      return Object.assign({}, state, {
        isLoading: true
      })

    case GET_UNMOUNT_COUPON:
      return Object.assign({}, state, {
        isLoading: false,
        isSearch: false,
        data: []
      })

    case ACTIVATED_REDEEM_CODE:
      Object.assign(state.data, state, {
        trans_activate_date: (action.data && action.data.trans_activate_date) || state.data.trans_activate_date,
        status: (action.data && action.data.status) || state.data.status
      })
      return Object.assign({}, state, {
        isLoading: false,
      })

    case GET_RECEIPT_COUPON:
      let check_loadmore = parseInt(initState.receiptCoupon.limit) === (action.data && action.data.list && action.data.list.length) && (action.total > (parseInt(initState.receiptCoupon.limit)) + Number(action.data.offset))
      return Object.assign({}, state, {
        receiptCoupon: Object.assign({}, state.receiptCoupon, {
          offset: action.data && Number(action.data.offset),
          total: action.data && action.data.total,
          list: action.data && action.data.list,
          loadmore: check_loadmore
        })
      })

    case GET_RECEIPT_COUPON_MORE:
      let check_loadmore2 = parseInt(initState.receiptCoupon.limit) === (action.data && action.data.list && action.data.list.length) && (action.total > (parseInt(initState.receiptCoupon.limit)) + Number(action.data.offset))
      let listMore = action.data && action.data.list
        ? [...state.receiptCoupon.list, ...action.data.list]
        : [...state.receiptCoupon.list]
      return Object.assign({}, state, {
        receiptCoupon: Object.assign({}, state.receiptCoupon, {
          total: action.data && action.data.total,
          offset: action.data && Number(action.data.offset),
          list: listMore,
          loadmore: check_loadmore2
        })
      })

    case GET_RECEIPT_COUPON_END:
      return Object.assign({}, state, {
        receiptCoupon: initState.receiptCoupon
      })

    case CREATE_RECEIPT_COUPON:
    case DELETE_RECEIPT_COUPON:
    case UPDATE_RECEIPT_COUPON:
      return state

    case GET_GIVEAWAY_COUPON:
      let check_load_giveaway = (parseInt(initState.giveawayCoupon.limit) === (action.data && action.data.length)) && (action.total > (parseInt(initState.giveawayCoupon.limit)) + Number(action.offset))
      return Object.assign({}, state, {
        giveawayCoupon: Object.assign({}, state.giveawayCoupon, {
          offset: Number(action.offset),
          list: action.data,
          loadmore: check_load_giveaway
        })
      })
    
    case GET_ALL_GIVEAWAY_COUPON:
      return Object.assign({}, state, {
        allGiveawayCoupon: Object.assign({}, state.allGiveawayCoupon, {
          list: action.data
        })
      })  

    case GET_GIVEAWAY_COUPON_MORE:
      let check_load_giveaway2 = parseInt(initState.giveawayCoupon.limit) === (action.data && action.data.length) && (action.total > (parseInt(initState.giveawayCoupon.limit)) + Number(action.offset))
      let giveaway_more = action.data.length > 0
        ? [...state.giveawayCoupon.list, ...action.data]
        : [...state.giveawayCoupon.list]
      return Object.assign({}, state, {
        giveawayCoupon: Object.assign({}, state.giveawayCoupon, {
          offset: Number(action.offset),
          list: giveaway_more,
          loadmore: check_load_giveaway2
        })
      })

    case UPDATE_GIVEAWAY_COUPON:
      return state

    case GET_GIFTVOUCHER_COUPON:
      let check_load_giftvoucher = (parseInt(initState.giftvoucher.limit) === (action.data && action.data.length)) && (action.total > (parseInt(initState.giftvoucher.limit)) + Number(action.offset))
      return Object.assign({}, state, {
        giftvoucher: Object.assign({}, state.giftvoucher, {
          offset: Number(action.offset),
          list: action.data,
          loadmore: check_load_giftvoucher
        })
      })

    case GET_GIFTVOUCHER_COUPON_MORE:
      let check_load_giftvoucher2 = (parseInt(initState.giftvoucher.limit) === (action.data && action.data.length)) && (action.total > (parseInt(initState.giftvoucher.limit)) + Number(action.offset))
      let givevloucher_more = action.data.length > 0
        ? [...state.giftvoucher.list, ...action.data]
        : [...state.giftvoucher.list]
      return Object.assign({}, state, {
        giftvoucher: Object.assign({}, state.giftvoucher, {
          offset: Number(action.offset),
          list: givevloucher_more,
          loadmore: check_load_giftvoucher2
        })
      })

    case UPDATE_GIFTVOUCHER_COUPON:
      return state
              
    default:
      return state
  }
}