import React from 'react'
import { Spinner } from 'reactstrap'

export default class extends React.Component {
  render() {
    let { type, size, margin, padding } = this.props;
    size = size || '3.2rem';
    margin = margin || '30px';
    padding = padding || '10px'
    return <div className='d-flex justify-content-center align-items-center' style={{ padding: padding, margin: margin }}>
      <Spinner style={{ width: size, height: size, color: '#546174' }} type={type} />
    </div>
  }
}