import { TUTORIAL } from '../actions_const'

export default (state = { isOpenTutorial: false }, action) => {
  switch (action.type) {
    case TUTORIAL.TOGGLE_OPEN_TUTORIAL:
      return Object.assign({}, state, {
        isOpenTutorial: action.data
      })

    default:
      return state
  }
}

