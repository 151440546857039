import {
  SET_MEMBER_LIST,
  SET_PREMEMBER_LIST,
  SET_LOADING_MEMBER_LIST,
} from './../actions_const';

import { MEMBER } from '../actions_const'
const { GET_MEMBER_TOTAL } = MEMBER

const initState = {
  isLoading: false,
  isLoadingLabel: false,
  member: [],
  premember: [],
  total: '',
  limit: 15,
  currentPage: 0,
  stats: {
    member: 0,
    subscriber: 0
  },
  userInfo: {},
  couponMember: [],
  couponMemberTotal: 0,
  personalCard: {},
  toggleEditAliasNameModal: false,
  memberReceiveReward: [],
  isResetFilterMember: false,
}

export default (state = initState, action) => {
  switch (action.type) {
    case MEMBER.GET_MEMBER_PROFILE:
      let thisState = Object.assign({}, state)
      thisState.userInfo[action.data.member_badge_code] = action.data
      return thisState

    case SET_MEMBER_LIST:
      return Object.assign({}, state, {
        isLoading: false,
        member: action.data.data,
        premember: [],
        total: action.data.total,
        currentPage: action.currentPage
      })

    case SET_PREMEMBER_LIST:
      return Object.assign({}, state, {
        isLoading: false,
        member: [],
        premember: action.data.data,
        total: action.data.total,
        currentPage: action.currentPage
      })

    case SET_LOADING_MEMBER_LIST:
      return Object.assign({}, state, {
        isLoading: true,
        member: [],
        premember: []
      })

    case GET_MEMBER_TOTAL:
      Object.assign(state.stats, {
        [action.data.type]: action.data.total
      })
      return state;
    
    case MEMBER.TOGGLE_EDIT_ALIAS_NAME_MODAL:
      return Object.assign({}, state, {
        toggleEditAliasNameModal: !state.toggleEditAliasNameModal
      })

    case MEMBER.UPDATE_ALIAS_NAME:
      return Object.assign({}, state, {
        toggleEditAliasNameModal: false,
        userInfo: Object.assign({}, state.userInfo, {
          [action.data.member_badge_code]: Object.assign(state.userInfo[action.data.member_badge_code], { alias_name: action.data.name })
        })
      })


    case MEMBER.GET_MEMBER_COUPON:
      return Object.assign({}, state, {
        couponMember: action.data.data,
        couponMemberTotal: action.data.total
      })

    case MEMBER.CLEAR_MEMBER_COUPON:
      return Object.assign({}, state, {
        couponMember: [],
        couponMemberTotal: 0
      })

    case MEMBER.RESOTRE_MEMBER_INFOMATION:
      return initState

    case MEMBER.GET_PERSONAL_MEMBER_CARD_INFO:
      if (!action.member_badge_code)
        return state

      let _getPersonal = Object.assign({}, state)
      _getPersonal.personalCard[action.member_badge_code] = action.data
      return _getPersonal


    case MEMBER.CREATE_PERSONAL_MEMBER_CARD_INFO:
      if (!action.member_badge_code)
        return state

      let _updatePersonal = Object.assign({}, state)
      _updatePersonal.personalCard[action.member_badge_code] = action.data
      return _updatePersonal

    case MEMBER.START_UPDATE_LABEL_MEMBER:
      return Object.assign({}, state, { isLoadingLabel: true })

    case MEMBER.UPDATE_LABEL_MEMBER:
      let member_badge_code = action.data && action.data.member_badge_code

      if (!member_badge_code)
        return Object.assign({}, state, { isLoadingLabel: false })

      return Object.assign({}, state, {
        isLoadingLabel: false,
        userInfo: Object.assign({}, state.userInfo, {
          [member_badge_code]: Object.assign(state.userInfo[member_badge_code], { member_label: action.data.member_label })
        })
      })
    
    case MEMBER.RESET_FILTER_MEMBER:
      return Object.assign({}, state,{
        isResetFilterMember: action.data
      })

    default:
      return state;
  }
};
