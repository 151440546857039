import "react-datepicker/dist/react-datepicker.css";

import _, { isArray, isString } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import styled from "styled-components";
import { DangerBtn, InvertBtn, SuccessBtn } from "../../../components/Button";
import { Title1, Title2, Title3 } from "../../../components/Font";
import ECouponModal from "../../Ecard/Ecoupon/eCouponModal";
import DatePicker from "react-datepicker";
import { enUS, th } from "date-fns/esm/locale";
import ECouponCard from "../../Ecard/Ecoupon/eCouponCard";
import { formatDateFull2 } from "../../../libs/date";
import TargetGroups from "../../../components/TargetGroups";
import {
  createCampaign,
  deleteCampaign,
  setTrigger,
  updateCampaign,
} from "../../../redux/actions/action_automation";
import { toast } from "react-toastify";
import { ToastBar } from "../../../components/ToastComponent/Toast.style";
import { FREQUENCY_TYPES } from "./const";
import { theme } from "../../../styles/theme";
import moment from "moment";
import { DescriptionText } from "../Automations.style";
import { ErrorTargetGroup } from "../../../components/TargetGroups/TargetGroupStyle.style";

const MAX_INPUT = 1000;

export default function CampaignEditor({
  business,
  process,
  campaign,
  onChanged,
  onCancel,
}) {
  const isMounted = useRef();
  const dispatch = useDispatch();
  const dictionary = useSelector((state) => state.language.dictionary);

  const { trigger } = useSelector((state) => state.automation);
  const [data, setData] = useState(
    campaign || {
      title: "",
      start_due: new Date(),
      stop_due: moment(new Date()).endOf("day"),
      frequency: "daily",
      ecard_info: {},
    }
  );
  const [isLoading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    start_due: ecard_start,
    stop_due: ecard_stop,
    target_type,
    target_value,
    ecard_life_time,
  } = data.ecard_info;
  const isEditing = !_.isEmpty(campaign) && campaign.campaign_id > 0;
  const isDuplicate = process === "duplicate";
  const method = isEditing
    ? "Update-campaign"
    : isDuplicate
    ? "Duplicate-campaign"
    : "Create-campaign"; // duplicate : update-campaign

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (
      trigger === "new_members" &&
      !(
        data.ecard_info.target_type === "member_points" ||
        data.ecard_info.target_type === "birthmonth"
      ) &&
      Object.keys(data.ecard_info).length !== 0
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [trigger, data]);

  const optionProps = useMemo(() => {
    // methods that allows to request ('Update', 'Update-campaign')
    let key = "",
      targetOption = {};
    let noRequest = true;
    let newValue = target_value;
    const isCreate = method === "Create-campaign" && !_.isEmpty(newValue);
    const disabled = Boolean(isEditing || isDuplicate || isCreate);
    const showValue = Boolean(isEditing || isDuplicate || isCreate);

    if (
      ["rfm_groups", "rfm_groups_6m", "rfm_groups_12m"].indexOf(target_type) >
      -1
    ) {
      key = "targetRFM";
      newValue = { range: target_type, segment: target_value };
    } else if (target_type === "member_card") {
      key = "targetMemberCard";
    } else if (target_type === "birthmonth") {
      key = "targetBirthMonth";
    } else if (target_type === "member_purchase_product") {
      key = "targetPurchaseProduct";
      newValue = isString(newValue) ? JSON.parse(newValue) : newValue;
      targetOption = {
        noDateSelect: isEditing || !isCreate,
        excludeArchive: !showValue, // false for show archive products
      };
    } else if (target_type === "member") {
      key = "targetSpecificMember";
      newValue = (
        isString(newValue)
          ? newValue.split(",")
          : isArray(newValue)
          ? newValue
          : []
      ).map((item) => {
        return {
          value: item,
          member_badge_code: item,
          isValid: true,
        };
      });
      targetOption = {
        hideTagInput: true,
      };
    } else if (target_type === "member_label") {
      key = "targetMemberLabel";
      targetOption = {
        readonly: isEditing || isCreate || isDuplicate,
        disabledLabel: false,
        hideAddButton: isEditing || isCreate || isDuplicate,
      };
    } else if (target_type === "member_points") {
      key = "targetMemberPoints";
      newValue = isString(newValue) ? JSON.parse(newValue) : newValue;
    } else if (target_type === "member_pending_group") {
      key = "targetPreMember";
    } else {
      key = "targetAllMember";
    }

    if (!showValue || (isDuplicate && !isMounted.current)) newValue = undefined;

    return Object.assign(
      {},
      {
        [key]: targetOption,
        noRequest,
        value: newValue,
        disabled,
        showTargetAsLabel: true,
        showAsLabel: true,
        hideTargetValue: isDuplicate && !isMounted.current,
      }
    );
  }, [target_type, target_value, isEditing, isDuplicate, method]);

  const target = useMemo(() => {
    return (
      <TargetGroups
        key={new Date().getTime()}
        options={{ isAutomation: true, ...optionProps }}
        disabled={true}
        target={target_type}
        max={MAX_INPUT}
      />
    );
  }, [target_type, optionProps]);

  const onApplyEcard = (info) => {
    info.update_time = new Date().getTime();
    setData({
      ...data,
      ecard_info: info,
    });
  };

  const onChangeDateStart = (date) => {
    if (!date) {
      date = new Date();
    }

    setData({
      ...data,
      start_due: moment(date).startOf("day")._d,
      stop_due:
        data.stop_due < date ? moment(date).endOf("day")._d : data.stop_due,
    });
  };

  const onChangeDateEnd = (date) => {
    if (!date) {
      date = new Date();
    }

    setData({
      ...data,
      stop_due:
        date < data.start_due ? data.start_due : moment(date).endOf("day")._d,
    });
  };

  const onChange = ({ target: { name, value } }) => {
    if (name === "start_due") {
      onChangeDateStart(new Date());
    } else if (name === "stop_due") {
      onChangeDateEnd(new Date());
    } else {
      if (name === "frequency") {
        dispatch(setTrigger(value));
      }
      return setData({ ...data, [name]: value });
    }
  };

  const addCampaign = () => {
    setLoading(true);
    const callback = (err) => {
      if (err) {
        setLoading(false);
        toast.error(
          <ToastBar>
            <Title2 bold white style={{ width: "100%", textAlign: "center" }}>
              {dictionary.error_occurred}
            </Title2>
          </ToastBar>
        );
      } else {
        toast.success(
          <ToastBar>
            <Title2 bold white style={{ width: "100%", textAlign: "center" }}>
              {isEditing
                ? dictionary.campaign_edit_success
                : dictionary.campaign_create_success}
            </Title2>
          </ToastBar>
        );
        onChanged();
      }
    };

    if (isEditing) {
      dispatch(updateCampaign(business.business_code, data, callback));
    } else {
      dispatch(createCampaign(business.business_code, data, callback));
    }
  };

  const toggleConfirmModal = () => {
    setShowConfirm(!showConfirm);
  };

  const onDelete = () => {
    setLoading(true);
    setShowConfirm(false);
    dispatch(
      deleteCampaign(business.business_code, campaign.campaign_id, (err) => {
        if (err) {
          setLoading(false);
          toast.error(
            <ToastBar>
              <Title2 bold white style={{ width: "100%", textAlign: "center" }}>
                {dictionary.error_occurred}
              </Title2>
            </ToastBar>
          );
        } else {
          toast.success(
            <ToastBar>
              <Title2 bold white style={{ width: "100%", textAlign: "center" }}>
                {dictionary.campaign_delete_success}
              </Title2>
            </ToastBar>
          );
          onChanged();
        }
      })
    );
  };

  const verifyCouponFields = () => {
    const {
      image,
      title,
      description,
      start_due,
      stop_due,
      action_type,
      ecard_life_time,
      target_type,
      target_value,
    } = data.ecard_info;
    let duration = ecard_life_time ? !ecard_life_time : !start_due || !stop_due;
    let result = !image || !title || !description || !action_type || duration;
    if (!result && method === "Duplicate-campaign") {
      if (
        ["birthmonth", "rfm_groups", "rfm_groups_6m", "rfm_groups_12m"].indexOf(
          target_type
        ) > -1
      ) {
        result = Boolean(result || !(target_value > 0));
      }
      if (
        ["member_card", "member_label", "member_points"].indexOf(target_type) >
        -1
      ) {
        result = Boolean(result || _.isEmpty(target_value));
      }
      if (target_type === "member") {
        result = Boolean(
          result || _.isEmpty(target_value) || target_value.length > 1000
        );
      }
      if (target_type === "member_purchase_product") {
        result = Boolean(
          result || _.isEmpty(_.get(target_value, "products", []))
        );
      }
    }

    if (ecard_life_time) {
      result = Boolean(
        result ||
          !(
            ecard_life_time &&
            parseInt(ecard_life_time) > 0 &&
            parseInt(ecard_life_time) < 366
          )
      );
    } else {
      result = Boolean(result || !(start_due <= stop_due));
    }

    return result;
  };

  return (
    <Card className="mb-3">
      <CardHeader>
        <Title2 bold>
          {isEditing
            ? dictionary.campaign_editor_edit
            : dictionary.campaign_editor_create}
        </Title2>
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-column flex-lg-row">
          <div className="col-lg-6">
            <FormGroup>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <Title2 className="pb-2" bold>
                  <span className="text-danger">*</span>{" "}
                  {dictionary.campaign_title}
                </Title2>
                <Title3 className="pb-2" secondary>{`(${
                  data.title.length || 0
                } / 50)`}</Title3>
              </div>
              <Input
                type="text"
                name="title"
                value={data.title}
                onChange={onChange}
                maxLength={50}
              />
            </FormGroup>
            <Title2 className="pb-2" bold>
              <span className="text-danger">*</span>{" "}
              {dictionary.campaign_duration}
            </Title2>
            <div className="align-items-center d-flex mb-3">
              <FormGroup className="w-50 mb-0" style={{ zIndex: 999 }}>
                <DatePicker
                  name="start_due"
                  disabled={isLoading}
                  fixedHeight
                  selectsStart
                  showYearDropdown
                  showMonthDropdown
                  locale={dictionary.locale === "en" ? enUS : th}
                  minDate={new Date()}
                  className="form-control"
                  wrapperClassName="w-100"
                  dateFormat="dd MMM yyyy"
                  popperPlacement="top-start"
                  startDate={new Date(data.start_due)}
                  selected={new Date(data.start_due)}
                  endDate={new Date(data.stop_due)}
                  onChangeRaw={onChange}
                  onChange={onChangeDateStart}
                />
              </FormGroup>
              <Title2 className="pt-2 px-3">-</Title2>
              <FormGroup className="w-50 mb-0" style={{ zIndex: 999 }}>
                <DatePicker
                  name="stop_due"
                  disabled={isLoading}
                  selectsEnd
                  fixedHeight
                  showYearDropdown
                  showMonthDropdown
                  locale={dictionary.locale === "en" ? enUS : th}
                  dateFormat="dd MMM yyyy"
                  className="form-control"
                  wrapperClassName="w-100"
                  popperPlacement="top-end"
                  popperClassName="endDatePicker"
                  startDate={new Date(data.start_due)}
                  endDate={new Date(data.stop_due)}
                  selected={new Date(data.stop_due)}
                  minDate={new Date(data.start_due)}
                  onChangeRaw={onChange}
                  onChange={onChangeDateEnd}
                />
              </FormGroup>
            </div>
            <FormGroup>
              <Title2 className="pb-2" bold>
                <span className="text-danger">*</span>{" "}
                {dictionary.campaign_frequency}
              </Title2>
              <Input
                type="select"
                name="frequency"
                value={data.frequency}
                onChange={onChange}
                disabled={isEditing}
              >
                {FREQUENCY_TYPES.map((item) => (
                  <option key={item.key} value={item.key}>{`${
                    dictionary[item.title]
                  }`}</option>
                ))}
              </Input>

              {trigger !== "new_members" && (
                <DescriptionText>
                  <Title2>{dictionary.campaign_trigger_description}</Title2>
                </DescriptionText>
              )}
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <EcardHolder
              dictionary={dictionary}
              isAutomation={true}
              ecard_info={data.ecard_info}
              onApplyEcard={onApplyEcard}
              method={method}
              campaign_title={data.title}
              onDuplicate={isMounted.current}
              showModal={showModal}
              setShowModal={setShowModal}
            />
            {(ecard_start || ecard_life_time) && (
              <>
                <Title2 bold>
                  <span className="text-danger">*</span>{" "}
                  {dictionary.campaign_coupon_duration}
                </Title2>
                <Title2 className="pt-2 pl-3 mb-3" secondary>
                  {ecard_life_time
                    ? `${dictionary.expired_after_receiving_coupon} ${ecard_life_time} ${dictionary.day}`
                    : `${formatDateFull2(ecard_start)} - ${formatDateFull2(
                        ecard_stop
                      )}`}
                  {!ecard_life_time &&
                    ecard_start &&
                    ecard_stop &&
                    moment(ecard_stop)
                      .endOf("day")
                      .isBefore(moment(data.stop_due).endOf("day")) && (
                      <>
                        <i
                          id="tooltip-coupon-duration"
                          className="fas fa-exclamation-triangle mx-2"
                          style={{ color: theme.orange }}
                        ></i>
                        <UncontrolledTooltip
                          target="tooltip-coupon-duration"
                          placement="bottom"
                        >
                          {dictionary.campaign_coupon_duration_warning}
                        </UncontrolledTooltip>
                      </>
                    )}
                </Title2>
              </>
            )}
            {isError ? (
              <FormGroup>
                <Title2 bold>
                  <span className="text-danger"> *</span>{" "}
                  {dictionary.target_groups}{" "}
                </Title2>
                <ErrorTargetGroup>
                  <Title3 bold danger>
                    {dictionary.campaign_please_select_target_group_again}
                  </Title3>
                </ErrorTargetGroup>
              </FormGroup>
            ) : (
              target_type && target
            )}
          </div>
        </div>
      </CardBody>
      <CardBody className="d-flex justify-content-between border-top">
        <div>
          {isEditing && (
            <DangerBtn
              md
              bold
              onClick={toggleConfirmModal}
              disabled={isLoading}
            >
              {dictionary.delete}
            </DangerBtn>
          )}
        </div>
        <div>
          <InvertBtn onClick={onCancel} md bold>
            {dictionary.cancel}
          </InvertBtn>
          <SuccessBtn
            onClick={addCampaign}
            disabled={
              !data.title ||
              _.isEmpty(data.ecard_info) ||
              isLoading ||
              (verifyCouponFields() && method === "Duplicate-campaign") ||
              isError
            }
          >
            {isLoading ? (
              <i className="fas fa-spinner fa-pulse" />
            ) : isEditing ? (
              dictionary.save
            ) : (
              dictionary.add
            )}
          </SuccessBtn>
        </div>
      </CardBody>
      {showConfirm && (
        <ConfirmDeleteModal
          dictionary={dictionary}
          isOpen={showConfirm}
          toggle={toggleConfirmModal}
          onConfirm={onDelete}
        />
      )}
    </Card>
  );
}

const EcardHolder = ({
  dictionary,
  ecard_info = {},
  onApplyEcard,
  method,
  campaign_title,
  onDuplicate,
  showModal,
  setShowModal,
}) => {
  useEffect(() => {
    if (method === "Duplicate-campaign") toggleModal();
    // eslint-disable-next-line
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onApply = (info) => {
    setShowModal(false);
    onApplyEcard(info);
  };

  return (
    <EcardHolderContainer>
      {!_.isEmpty(ecard_info) ? (
        <div style={{ cursor: "pointer" }} onClick={toggleModal}>
          <ECouponCard
            key={ecard_info.update_time}
            value={ecard_info}
            selectOpacity={true}
          />
        </div>
      ) : (
        <div className="placeholder" onClick={toggleModal}>
          <Title1>
            <i className="fas fa-ticket-alt" />
          </Title1>
          <Title2 bold>{dictionary.coupon_promotion_create}</Title2>
        </div>
      )}
      {showModal && (
        <ECouponModal
          onDuplicate={onDuplicate}
          value={ecard_info}
          method={method}
          toggle={toggleModal}
          isOpen={showModal}
          onApply={onApply}
          isAutomation={true}
          campaign_title={campaign_title}
        />
      )}
    </EcardHolderContainer>
  );
};

const ConfirmDeleteModal = ({ dictionary, isOpen, toggle, onConfirm }) => {
  return (
    <Modal fade centered isOpen={isOpen}>
      <ModalHeader>
        <Title2 bold>{dictionary.notification}</Title2>
      </ModalHeader>
      <ModalBody className="text-center">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: 150 }}
        >
          <Title2 bold>{dictionary.campaign_confirm_delete}</Title2>
        </div>
      </ModalBody>
      <ModalFooter>
        <InvertBtn md bold onClick={toggle}>
          {dictionary.close}
        </InvertBtn>
        <DangerBtn md bold id={`btn-delete`} onClick={onConfirm}>
          {dictionary.delete}
        </DangerBtn>
      </ModalFooter>
    </Modal>
  );
};

const EcardHolderContainer = styled.div`
  background-color: #efefef;
  width: 100%;
  border-radius: 16px;
  margin: 16px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  .placeholder {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .fas {
      font-size: 30px;
    }
  }
`;
