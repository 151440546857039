import { TOGGLE_SIDEBAR } from '../actions_const'

const initState = {
  toggle: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return Object.assign({}, state, {
        toggle: !state.toggle
      })
    default:
      return state
  }
}

