import { BROADCAST } from '../actions_const'

let initState = {
  broadcast_info: [],
  broadcast_promotion: [],
  limit: 10,
  offset: 0,
  total: 0,
  loadmore: false,
  broadcast_target_loading: false,
  broadcast_target: {
    target: 'all',
    target_data: null,
    total: 0,
    line_total: 0,
    shappy_total: 0,
    inter_total: 0,
  },
  broadcast_option: {
    message_id: null,
    provider: '',
    total: 0,
    chunk: 0
  },
  target_rfm: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case BROADCAST.GET_BROADCAST_INFO:
      let check_load_info = parseInt(initState.limit) === (action.data && action.data.data && action.data.data.length)
      return Object.assign({}, state, {
        broadcast_info: action.data.data,
        offset: (action.data && action.data.offset) || state.offset,
        total: (action.data && action.data.total) || state.total,
        loadmore: check_load_info
      })

    case BROADCAST.GET_BROADCAST_INFO_MORE:
      let check_load_info2 = parseInt(initState.limit) === (action.data && action.data.data && action.data.data.length)
      return Object.assign({}, state, {
        broadcast_info: [...state.broadcast_info, ...action.data.data],
        offset: (action.data && action.data.offset) || state.offset,
        total: (action.data && action.data.total) || state.total,
        loadmore: check_load_info2
      })

    case BROADCAST.GET_BROADCAST_PROMOTION:
      let check_load_promo = parseInt(initState.limit) === (action.data && action.data.data && action.data.data.length)
      return Object.assign({}, state, {
        broadcast_promotion: action.data.data,
        offset: (action.data && action.data.offset) || state.offset,
        total: (action.data && action.data.total) || state.total,
        loadmore: check_load_promo
      })

    case BROADCAST.GET_BROADCAST_PROMOTION_MORE:
      let check_load_promo2 = parseInt(initState.limit) === (action.data && action.data.data && action.data.data.length)
      return Object.assign({}, state, {
        broadcast_promotion: [...state.broadcast_promotion, ...action.data.data],
        offset: (action.data && action.data.offset) || state.offset,
        total: (action.data && action.data.total) || state.total,
        loadmore: check_load_promo2
      })

    case BROADCAST.CLEAR_BROADCAST:
      return Object.assign({}, state, initState)

    case BROADCAST.CREATE_BROADCAST_INFO:
    case BROADCAST.UPDATE_BROADCAST_PROMOTION:
    case BROADCAST.UPDATE_BROADCAST_INFO:
      return state
    case BROADCAST.GET_TARGET_MEMBER_TOTAL:
      return Object.assign({}, state, {
        broadcast_target_loading: false,
        broadcast_target: {
          target: action.data.target || null,
          target_data: action.data.target_data || 0,
          total: action.data.total || 0,
          line_total: action.data.line_total || 0,
          shappy_total: action.data.shappy_total || 0,
          inter_total: action.data.inter_total || 0,
          billing: action.data.billing
        }
      })
    case BROADCAST.TARGET_LOADING: 
      return Object.assign({}, state, {
        broadcast_target_loading: action.data.loading
      })
    case BROADCAST.INIT_BROADCAST_OPTION: 
      return Object.assign({}, state, initState.broadcast_option)
    case BROADCAST.INIT_BROADCAST_TARGET: 
      return Object.assign({}, state, { broadcast_target: { ...initState.broadcast_target, target: action.data.target } } )
    case BROADCAST.SET_BROADCAST_OPTION:
      return Object.assign({}, state, {
        broadcast_option: {
          ...state.broadcast_option,
          ...action.data
        }
      })
    case BROADCAST.GET_TARGET_RFM:
      return Object.assign({}, state, {
        broadcast_target_loading: false,
        target_rfm: action.data
      })
    case BROADCAST.UPDATE_TARGET_VALUE:
      return Object.assign({}, state, {
        broadcast_target: {
          ...state.broadcast_target,
          target_data: action.data
        }
      })

    default:
      return state
  }
}