import { GET_POINTS_REQUEST, IS_LOADING_GET_POINTS_REQUEST, IS_LOADING_UPDATE_POINTS_REQUEST, GET_POINTS_EARNED_LAST_30_DAYS } from "../actions_const";

let initState = {
  isLoadingGetPointsRequest: false,
  isLoadingUpdatePointsRequest: false,
  pointsRequestList: [],
  currentPage: 0,
  total: 0,
  totalProductFilter: 0,
  pointsEarnedLast30Days: 0,
};

export default (state = initState, action) => {
  switch (action.type) {
    case IS_LOADING_GET_POINTS_REQUEST:
      return Object.assign({}, state, {
        isLoadingGetPointsRequest: true,
      });
    case IS_LOADING_UPDATE_POINTS_REQUEST:
      return Object.assign({}, state, {
        isLoadingUpdatePointsRequest: action.data,
      });
    case GET_POINTS_REQUEST:
      return Object.assign({}, state, {
        isLoadingUpdatePointsRequest: false,
        isLoadingGetPointsRequest: false,
        pointsRequestList: action.data || [],
        currentPage: action.currentPage,
        total: action.data.length > 0 ? action.data[0].total : 0,
        totalProductFilter: action.data.length > 0 ? action.data[0].total_product_cp : 0
      });
    case GET_POINTS_EARNED_LAST_30_DAYS:
      return Object.assign({}, state, {
        pointsEarnedLast30Days: action.data,
      });
    default:
      return state;
  }
};
