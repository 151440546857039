import React from 'react'
import { Title1 } from '../Font'
import styled from 'styled-components'
import logoPointspot from '../../assets/img/m-logo-96.png'

export default class extends React.Component {
  render() {
    return <AlertLayout id="pointspot-alert-box">
      <AlertBox>
        <AlertDetail>
          <AlertDetailImg>
            <ImgPointspot alt='' src={logoPointspot} />
          </AlertDetailImg>
          <AlertDetailText>
            <Title1>Pointspot, New version is now available</Title1>
          </AlertDetailText>
        </AlertDetail>
        <AlertButton onClick={() => { window.location.reload(true); }}>
          <Title1 white>OK</Title1>
        </AlertButton>
      </AlertBox>
    </AlertLayout>
  }
}

const AlertLayout = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
  display: none;
  z-index: 10000;
`

const AlertBox = styled.div`
  width: 90%;
  max-width: 400px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #FFFFFF;
`

const AlertDetail = styled.div`
  padding: 25px 20px;
`

const AlertDetailImg = styled.div`
  display: flex;  
  padding: 20px 0px; 
  align-items: center;
  justify-content: center;
`

const ImgPointspot = styled.img`
  max-width: 90px;
`

const AlertDetailText = styled.div`
  display: flex;  
  color: #546174;
  font-size: 15px;
  padding: 10px 0px;  
  letter-spacing: 0.5px;
  justify-content: center;
`

const AlertButton = styled.div`
  padding: 15px; 
  color: #FFFFFF;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  background-color: #546174;
  border-top: 1px solid #CCC;
`