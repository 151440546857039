import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBusinessPackage } from '../../redux/actions/action_package'

import { hasValueInArray } from '../../libs/validator'
import { usePrevious } from '../../libs/hooks/usePrevious'
import Caching from '../../libs/caching'

import { Title2, Title3, Title4 } from '../Font'
import { DangerDot } from './BusinessPackage.style'

import { TransparentBtn, UpgradeBtn } from '../../components/Button'
import ModalPackage from './BusinessPackageModal'
import ModalSubscription from './BusinessSubscriptionModal'
import ModalSubscriptionNewPackage from './BusinessSubscriptionNewPackageModal'
import Loading from '../Loading'

const permission_key = 'setting-packages'

export default function BusinessPackage() {
  const [isOpenPackage, setIsOpenPackage] = useState(false)
  const [isOpenSubscription, setIsOpenSubscription] = useState(false)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const [triggerBusiness, setTriggerBusiness] = useState(false)

  const dispatch = useDispatch()
  const reloaded = useRef(false)
  const { dictionary } = useSelector((state) => state.language)
  const business_code = useSelector((state) => state.business.current.business_code)
  const psPackageList = useSelector((state) => state.packages.packages_list)
  const businessPackage = useSelector((state) => state.packages.packages)
  const { subscription } = useSelector((state) => state.packages);
  const previousBusinessCode = usePrevious(business_code);
  
  const isMounted = useRef(false);
  const { isNewPackage } = subscription;
  const getPackageBusiness = useCallback((business_code, loading) => {
    loading && setIsLoadingLocal(true)
    dispatch(getBusinessPackage({ business_code }, () => {
      loading && setIsLoadingLocal(false)
    }))

    let key = `BusinessPackage_${business_code}`
    if (!Caching.getCaching(key)) {
      Caching.setCaching(key, setInterval(() => {
        setTriggerBusiness(true)
      }, 60000))
    }
  }, [dispatch])

  useEffect(() => {
    if (!isMounted.current) {
      getPackageBusiness(business_code, true)
    }

    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  }, [business_code, getPackageBusiness])

  useEffect(() => {
    if (previousBusinessCode !== business_code) {
      const key = `BusinessPackage_${previousBusinessCode}`;
      window.clearInterval && clearInterval(Caching.getCaching(key));
      Caching.clearCaching(key);
      getPackageBusiness(business_code)
    }
  }, [business_code, previousBusinessCode, getPackageBusiness])

  useEffect(() => {
    // active when change trigger and not first time
    if (triggerBusiness && isMounted.current) {
      const key = `BusinessPackage_${previousBusinessCode}`;
      window.clearInterval && clearInterval(Caching.getCaching(key));
      Caching.clearCaching(key);
      reloaded.current = true;
      getPackageBusiness(business_code);

      setTriggerBusiness(false)
    }
    
  }, [psPackageList, businessPackage, business_code, triggerBusiness, getPackageBusiness, previousBusinessCode]);

  const toggleModalPackagePlan = () => {
    setIsOpenPackage(!isOpenPackage)
  }

  const toggleModalSubscription = () => {
    setIsOpenSubscription(!isOpenSubscription)
  }

  const { packages, packages_list, isLoading, packages_free } = useSelector((state) => state.packages)
  const { current: { permission } } = useSelector((state) => state.business)
  const { usage_record } = useSelector((state) => state.usage)

  const packageItems = (packages && packages.package_data) || {}
  const this_permission = (permission && permission[permission_key]) || {}
  const permiss_packages = (permission && permission['setting-packages']) || {}

  return (
    <>
      <div className='border-top border-bottom' style={{ padding: '0.5rem 1rem' }}>
        <div className='d-flex align-items-center justify-content-between' style={{ minHeight: 50 }}>
          {
            isLoading || isLoadingLocal
              ? <LoadingBox />
              : <>
                <div>
                  <Title2 bold>{`${dictionary[packageItems.title_dictionary]}`}</Title2>
                  <Title4>{dictionary.current_usage_package}</Title4>
                </div>
                {Boolean(this_permission && this_permission.permission_view) &&
                  Boolean(packages.subscription_package) ? (
                  <TransparentBtn
                    onClick={
                      Boolean(packages.subscription_package)
                        ? toggleModalSubscription
                        : toggleModalPackagePlan
                    }
                  >
                    <Title3 bold link>
                      {dictionary.show_detail}
                      {
                        ((usage_record.credit_remaining_percent < 20 && !isNewPackage) ||
                        (isNewPackage && (usage_record.isTxCloseToLimit || usage_record.isCreditCloseToLimit))) &&
                        <DangerDot />
                      }
                    </Title3>
                  </TransparentBtn>
                ) : Boolean(permiss_packages && permiss_packages.permission_view) ? (
                  <UpgradeBtn
                    style={{ marginRight: "14px" }}
                    onClick={
                      Boolean(packages.subscription_package)
                        ? toggleModalSubscription
                        : toggleModalPackagePlan
                    }
                  >
                    {dictionary.upgrade}
                  </UpgradeBtn>
                ) : null}
              </>
          }
        </div>
      </div>
      {
        isOpenPackage
        && packages_list.length
        && <ModalPackage
          isOpen={isOpenPackage}
          toggle={toggleModalPackagePlan}
          toggleSubscription={toggleModalSubscription}
          packageItems={packages_list.filter(v => v.show)} />
      }
      {
        isOpenSubscription && !isNewPackage
        && (packages && !hasValueInArray(packages_free, packages.subscroption_package))
        && <ModalSubscription
          packageItems={packageItems}
          isOpen={isOpenSubscription}
          toggle={toggleModalSubscription}
          togglePackage={toggleModalPackagePlan} />
      }
      {
        isOpenSubscription && isNewPackage
        && (packages && !hasValueInArray(packages_free, packages.subscroption_package))
        && <ModalSubscriptionNewPackage
          packageItems={packageItems}
          isOpen={isOpenSubscription}
          toggle={toggleModalSubscription}
          togglePackage={toggleModalPackagePlan} />
      }
    </>
  )
}

const LoadingBox = () => {
  return <div className='w-100 d-flex align-items-center justify-content-center'>
    <Loading size='2rem' margin='0' />
  </div>
}
