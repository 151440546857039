import { CUSTOM_FORM } from '../actions_const'

let initState = {
  form_setting: [],
  form_setting_all: [],
  loading: false,
  loading_value: false,
  loading_update: false,
  profile_custom: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case CUSTOM_FORM.GET_CUSTOM_FORM_TITLE: 
      return Object.assign({}, state, { form_setting: action.data, loading: false })
    case CUSTOM_FORM.GET_CUSTOM_FORM_TITLE_ALL:
      return Object.assign({}, state, { form_setting_all: action.data, loading: false })
    case CUSTOM_FORM.IS_LOADING:
      return Object.assign({}, state, { loading: true })
    case CUSTOM_FORM.IS_LOADING_UPDATE:
      return Object.assign({}, state, { loading_update: true })
    case CUSTOM_FORM.IS_LOADING_CUSTOM_FORM_VALUE:
      return Object.assign({}, state, { loading_value: true })
    case CUSTOM_FORM.UPDATE_CUSTOM_FORM_TITLE:
      return Object.assign({}, state, { form_setting: action.data, loading_update: false })
    case CUSTOM_FORM.GET_CUSTOM_FORM_VALUE:
      return Object.assign({}, state, { profile_custom: action.data, loading_update: false, loading_value: false })
    case CUSTOM_FORM.CLEAR_CUSTOM_FORM_TITLE:
      return Object.assign({}, state, initState)
    default:
      return state
  }
}

