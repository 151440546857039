import { TOTP } from "../actions_const";

let initState = {
  verifyToken: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case TOTP.VERIFY_TOKEN:
      return Object.assign({}, state, {
        verifyToken: action.data,
      });

    case TOTP.SET_VERIFY_TOKEN:
      return Object.assign({}, state, {
        verifyToken: action.data,
      });

    default:
      return state;
  }
};
