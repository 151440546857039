import {
  ECOUPON_CLEAR,
  ECOUPON_LOADING,
  ECOUPON_FAIL,
  ECOUPON_GET,
  ECOUPON_GET_MORE,
  ECOUPON_GET_MEMBER_PROFILE,
  ECOUPON_GET_MEMBER_PROFILE_LOADING,
  ECOUPON_GET_MEMBER_PROFILE_SUCCESS,
  ECOUPON_CLEAR_MEMBER_PROFILE,
  ECOUPON_GET_ALL
} from '../actions_const'

const { REACT_APP_COUPON_LIMIT } = process.env

const initState = {
  isLoading: false,
  isProcess: false,
  isProcess2: false,
  isOpen: false,
  isReload: false,
  couponList: [],
  allCouponList: [],
  member: [],
  offset: 0,
  limit: parseInt(REACT_APP_COUPON_LIMIT),
  total: 0,
  loadmore: false
}

export default (state = initState, action) => {
  switch (action.type) {
    // NEW
    case ECOUPON_LOADING:
      return Object.assign({}, state, {
        isLoading: true
      })
    case ECOUPON_FAIL:
      return initState
    case ECOUPON_GET:
      let check_load = parseInt(initState.limit) === (action.data && action.data.data && action.data.data.length) && (action.total > (parseInt(initState.limit)) + Number(action.data.offset))
      return Object.assign({}, state, {
        isLoading: false,
        isReload: false,
        couponList: action.data && action.data.data,
        offset: action.data && Number(action.data.offset),
        total: action.data && action.data.total,
        loadmore: check_load
      })
    case ECOUPON_GET_ALL:
      return Object.assign({}, state, {
        allCouponList: action.data && action.data.data,
      })
    case ECOUPON_GET_MORE:
      let check_load2 = parseInt(initState.limit) === (action.data && action.data.data && action.data.data.length) && (action.total > (parseInt(initState.limit)) + Number(action.data.offset))
      let couponListMore = action.data && action.data.data
        ? [...state.couponList, ...action.data.data]
        : [...state.couponList]
      return Object.assign({}, state, {
        isLoading: false,
        isReload: false,
        couponList: couponListMore,
        offset: action.data && Number(action.data.offset),
        total: action.data && action.data.total,
        loadmore: check_load2
      })
    case ECOUPON_GET_MEMBER_PROFILE_LOADING:
      return Object.assign({}, state, {
        isProcess2: true
      })
    case ECOUPON_GET_MEMBER_PROFILE:
      return Object.assign({}, state, {
        isProcess2: false,
        member: action.data
      })
    case ECOUPON_CLEAR_MEMBER_PROFILE:
      return Object.assign({}, state, {
        member: []
      })
    case ECOUPON_GET_MEMBER_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isProcess2: false
      })
    case ECOUPON_CLEAR:
      return initState
    default:
      return state
  }
}