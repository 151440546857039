import React, { Component } from "react";
import { useSelector } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";
import "./style.css";
import _ from "lodash";
export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			delimiters: [13, 32, 188],
			reactTagsInput: "",
			isPaste: false,
		};

		this.addTag = this.addTag.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.handleSelectFile = this.handleSelectFile.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.onKeysDown = this.onKeysDown.bind(this);
		this.onPaste = this.onPaste.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	addTag(value) {
		let { tags, validator, maxLength } = this.props;
		let index = tags.length;
		let valueSplit = value.split(/\s|,/);
		let dupliIn = [];
		let temp = valueSplit
			.map((val) => (val ? { value: val, isValid: false, pending: true } : undefined))
			.filter((v) => v !== undefined);

		temp = temp.map((v) => {
			if (_.find(dupliIn, { value: v.value })) {
				v.Exist = true;
				return v;
			} else {
				dupliIn.push(v);
				return v;
			}
		});

		let curTags = [...tags, ...temp];

		if (maxLength && curTags.length > maxLength) {
			//curTags = curTags.slice(0, maxLength);
			if (typeof this.props.onExceedLimit === "function") {
				this.props.onExceedLimit();
			}
		}

		this.props.handleChange(curTags);

		if (validator && typeof validator === "function") {
			this.props.validator(
				temp.map((tag) => tag.value),
				tags,
				(updatedElements) => {
					updatedElements.forEach((element, key) => {
						Object.assign(curTags[index + key], element);
					});
					this.props.handleChange(curTags);
				}
			);
		} else {
			temp.forEach((_element, key) => {
				Object.assign(curTags[index + key], {
					isValid: true,
					pending: false,
				});
				this.props.handleChange(curTags);
			});
		}
	}

	onPaste(e) {
		this.setState({ isPaste: true });
	}

	onBlur(e) {
		let { value, name } = e.target;
		this.addTag(value);
		this.setState({ [name]: "" });
	}

	handleChange(e) {
		let { value, name } = e.target;
		if (value.trim().match(/^\s|^,/)) return;
		if (this.state.isPaste) {
			this.addTag(value);
			this.setState({ [name]: "", isPaste: false });
		} else {
			this.setState({ [name]: value.trim() });
		}
	}

	onKeysDown(event) {
		const {
			keyCode,
			target: { value, name },
		} = event;
		let { delimiters } = this.state;
		if (value && delimiters.indexOf(keyCode) > -1) {
			this.addTag(value);
			this.setState({ [name]: "" });
		}
	}

	handleFileChange(e) {
		let { files } = e.target;
		if (!files.length || files[0].size > 1048576) return;
		let reader = new FileReader();
		reader.addEventListener("loadend", () => {
			this.addTag(reader.result.trim());
		});
		reader.readAsText(files[0]);
	}

	handleSelectFile() {
		this.refs.selectFile.click();
	}

	handleRemove(value) {
		let { tags } = this.props;
		let temp = tags;
		temp.splice(value, 1);
		this.props.handleChange(temp);
		this.forceUpdate();
	}

	render() {
		const { tags, placeholder, disabled, disableInput, className, hideInput, forwardRef = null } = this.props;
		let { reactTagsInput } = this.state;
		return (
			<div className={`reacttags ${className ? className : ""}`} style={{ width: "100%" }}>
				<div ref={forwardRef} className='reacttags_tags_area' hidden={!tags.length}>
					{tags.map((data, key) => (
						<ReactTags
							{...this.props}
							id={key}
							key={key}
							data={data}
							disabled={disabled}
							onRemove={this.handleRemove}
						/>
					))}
				</div>
				{!hideInput && (
					<div className='reacttags_input_area'>
						<input
							type='text'
							name='reactTagsInput'
							className='form-control'
							disabled={disableInput}
							value={reactTagsInput}
							placeholder={placeholder}
							onBlur={this.onBlur}
							onPaste={this.onPaste}
							onKeyDown={this.onKeysDown}
							onChange={this.handleChange}
						/>
						<span hidden></span>
						<input hidden type='button' value='select' onClick={this.handleSelectFile} />
						<input hidden type='file' accept='.csv' ref='selectFile' onChange={this.handleFileChange} />
					</div>
				)}
			</div>
		);
	}
}

const ReactTags = (props) => {
	const dictionary = useSelector((state) => state.language.dictionary);
	let {
		data: { value, isValid, pending, disenable, invalidReason },
		id,
		disabled,
	} = props;
	const textId = `tag-${id}`;
	return (
		<div
			className={` reacttags_tags
     ${pending ? "reacttags_tags_pending" : isValid ? "reacttags_tags_valid" : "reacttags_tags_invalid"}
    `}>
			<span id={textId} className='reacttags_tags_text'>
				{value}
			</span>
			{invalidReason && (
				<UncontrolledTooltip placement='bottom' target={textId}>
					{dictionary[invalidReason] || invalidReason}
				</UncontrolledTooltip>
			)}
			<ReactTagsRemoveBtn hidden={disenable || disabled} remove={() => props.onRemove(props.id)} />
		</div>
	);
};

const ReactTagsRemoveBtn = ({ hidden, remove }) => {
	return !hidden && <i className='fas fa-times reacttags_tags_remove' onClick={remove} />;
};
