import React, { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { AlertSection } from "./AddCreditAlert.style";
import { Title3 } from "../Font";
import ContactUsModal from "../BusinessPackage/ContactUsModal";

export const AddCreditAlert = () => {
  const { usage_record } = useSelector((state) => state.usage);
  const { dictionary } = useSelector((state) => state.language);
  const current = useSelector((state) => state.business.current);
  const { subscription } = useSelector((state) => state.packages);

  const [isOpenContact, setIsOpenContact] = useState(false);

  const showPermission = current?.permission?.['setting-packages'] || {};
  const { isNewPackage } = subscription;
  const showAlert = useMemo(() => {
    return current.is_use_credit && isNewPackage && showPermission?.permission_view 
      ? usage_record.isTxCloseToLimit || usage_record.isCreditCloseToLimit 
      : false;
  }, [current.is_use_credit, showPermission, usage_record, isNewPackage]);

  const toggleContact = useCallback(() => {
    setIsOpenContact(prev => !prev);
  }, []);

  return (
    <AlertSection hidden={!showAlert}>
      <Title3 danger>
        {usage_record.isTxCloseToLimit && dictionary.transactions_low}
        {usage_record.isCreditCloseToLimit && dictionary.credit_low}
      </Title3>
      <Title3 danger underline link className="pl-2" onClick={toggleContact}>
        {dictionary.contact_staff}
      </Title3>

      <ContactUsModal
        isOpen={isOpenContact}
        toggleContact={toggleContact}
        isPurchase
      />
    </AlertSection>
  );
};
