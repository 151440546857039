import { TRANSFERS, SET_BUSINESS_DROPDOWNLIST } from '../actions_const'

let initState = {
  isSearch: false,
  isProcess: false,
  isDirty: false,
  profile: [],
  error: [],
  recentPullTransaction: [],
  recentSendTransaction: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case TRANSFERS.PROFILE_SEARCHING:
      return Object.assign({}, state, {
        isSearch: true
      })
    case TRANSFERS.GET_MEMBER_PROFILE:
      return Object.assign({}, state, {
        isSearch: false,
        isDirty: true,
        profile: action.data,
      })
    case TRANSFERS.CLEAR_MEMBER_PROFILE:
      return Object.assign({}, state, {
        isSearch: false,
        isDirty: false,
        profile: [],
      })
    case TRANSFERS.CLEAR_ERROR:
      return Object.assign({}, state, {
        error: []
      })
    case TRANSFERS.TRANSFER_POINT_IS_PROCESS:
      return Object.assign({}, state, {
        isProcess: true,
        error: []
      })
    case TRANSFERS.TRANSFER_POINT_SUCCESS:
      let elem = action.data.type === 'pull'
        ? 'recentPullTransaction'
        : 'recentSendTransaction'
      action.data.timeStamp = new Date()
      return Object.assign({}, state, {
        isProcess: false,
        isDirty: false,
        profile: [],
        [elem]: [action.data, ...state[elem]]
      })
    case TRANSFERS.TRANSFER_POINT_UNSUCCESS:
      return Object.assign({}, state, {
        isProcess: false,
        error: [action.error]
      })
    case TRANSFERS.PRE_REGISTER:
      return Object.assign({}, state, {
        isProcess: false,
        profile: [action.data],
      })
    case SET_BUSINESS_DROPDOWNLIST:
      return initState
    default:
      return state
  }
}

