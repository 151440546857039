import { PAYMENT } from '../actions_const'

let initState = {
  isLoading: false,
  payment: {},
  card: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case PAYMENT.CLEAR_PATMENT: {
      return Object.assign({}, state, {
        payment: {},
        card: [],
      })
    }
    case PAYMENT.SET_PATMENT_LOADING:
      return Object.assign({}, state, {
        isLoading: action.loading,
      })
    case PAYMENT.GET_CUSTOMER_PAYMENT:
      return Object.assign({}, state, {
        payment: action.data,
      })
    case PAYMENT.GET_CUSTOMER_PAYMENT_CARD:
      return Object.assign({}, state, {
        card: action.data,
      })
    default:
      return state
  }
}