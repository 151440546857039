import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Editor } from '@tinymce/tinymce-react';
import html2bbcode from 'html2bbcode';
import { toast } from 'react-toastify';

import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/content/default/content.min.css';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/bbcode';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';

import { ToastBar } from '../../../../components/ToastComponent/Toast.style';
import { Title2 } from '../../../../components/Font';

import { messagesActions } from '../../store/messagesSlice';
import './NewMessageForm.css';

library.add(faCircle);
library.add(faQuestionCircle);

const HTML2BBCode = html2bbcode.HTML2BBCode;
const opts = new HTML2BBCode({
  noheadings: true,
});

function NewMessageForm() {
  const dispatch = useDispatch();

  const [inputUrl, setInputUrl] = useState('');
  const [value, setValue] = useState('');
  const [emptyMessage, setEmptyMessage] = useState(false);

  const editorRef = useRef(null);

  function urlChangeHandler(e) {
    setInputUrl(e.target.value);
  }

  function cancelSubmit() {
    setEmptyMessage(false);
  }

  function submitHandler(e) {
    e.preventDefault();
    let inputBBCode = '';

    if (editorRef.current) {
      const converter = new HTML2BBCode(opts);

      let videoID = '';
      if (editorRef.current.getContent().includes('embed/')) {
        videoID = editorRef.current.getContent().split('embed/')[1].split('"')[0];
      }

      const youtubeTag = '[youtube]' + videoID + '[/youtube]';
      const replacedHTML = editorRef.current
        .getContent()
        .replaceAll('\n', '\\n')
        .replaceAll('\\n\\n', '\\n')
        .replaceAll('\\n<h1>', '<h1>')
        .replaceAll('</h1>\\n', '</h1>')
        .replaceAll('\\n<h2>', '<h2>')
        .replaceAll('</h2>\\n', '</h2>')
        .replaceAll('\\n<h3>', '<h3>')
        .replaceAll('</h3>\\n', '</h3>')
        .replaceAll('\\n<h4>', '<h4>')
        .replaceAll('</h4>\\n', '</h4>')
        .replaceAll('\\n<h5>', '<h5>')
        .replaceAll('</h5>\\n', '</h5>')
        .replaceAll('\\n<h6>', '<h6>')
        .replaceAll('</h6>\\n', '</h6>')
        .replaceAll('<ul>\\n', '<ul>')
        .replaceAll('\\n</ul>\\n', '</ul>')
        .replaceAll('</li>\\n', '</li>')
        .replaceAll('\\n</video>', '</video>')
        .replaceAll('>\\n<source', '><source');

      inputBBCode =
        converter
          .feed(replacedHTML)
          .s.replaceAll('\n', '\\n')
          .replaceAll('\\n[h1]', '[h1]')
          .replaceAll('[/h1]\\n', '[/h1]')
          .replaceAll('\\n[h2]', '[h2]')
          .replaceAll('[/h2]\\n', '[/h2]')
          .replaceAll('\\n[h3]', '[h3]')
          .replaceAll('[/h3]\\n', '[/h3]')
          .replaceAll('\\n[h4]', '[h4]')
          .replaceAll('[/h4]\\n', '[/h4]')
          .replaceAll('\\n[h5]', '[h5]')
          .replaceAll('[/h5]\\n', '[/h5]')
          .replaceAll('\\n[h6]', '[h6]')
          .replaceAll('[/h6]\\n', '[/h6]')
          .replaceAll('\\n[li]', '[li]')
          .replaceAll('[/li]\\n', '[/li]')
          .replaceAll('[ul]', '[list]')
          .replaceAll('[/ul]', '[/list]')
          .replaceAll('[li]', '[*]')
          .replaceAll('[/li]', '[/*]')
          .replaceAll('[/img]\\n', '[/img]')
          .replaceAll('[left]', '')
          .replaceAll('[/left]', '')
          .replaceAll('\\n[center]', '[center]')
          .replaceAll('[/center]\\n', '[/center]')
          .replaceAll('\\n[right]', '[right]')
          .replaceAll('[/right]\\n', '[/right]')
          .trim() + `${videoID !== '' ? youtubeTag : ''}`;
    }

    if (inputBBCode === '') {
      setEmptyMessage(true);
      return;
    }

    toast['success'](
      <ToastBar>
        <Title2 bold white style={{ width: '100%', textAlign: 'center' }}>
          Message Added
        </Title2>
      </ToastBar>
    );

    dispatch(
      messagesActions.addNewMessage({
        id: Math.random().toString(),
        message: inputBBCode,
        date: new Date().toString(),
        timestamp: Date.parse(new Date().toString()),
        url: inputUrl,
        show: true,
      })
    );

    setValue('');
    setInputUrl('');
  }

  const infoString =
    'If you wish to add a YouTube video, please add it at the last line\nFor alignment: use [center]text[/center] or [right]text[/right]';

  return (
    <div>
      {emptyMessage &&
        createPortal(
          <>
            <div className="empty-message-overlay" onClick={cancelSubmit}></div>
            <div className="empty-message-popup">
              <p className="title">Empty Message</p>
              <p>Cannot add an empty message</p>
              <button className="okbtn" onClick={cancelSubmit}>
                OK
              </button>
            </div>
          </>,
          document.getElementById('broadcast-message-modal')
        )}
      <div className="new-message-nav">
        <p>
          <FontAwesomeIcon icon={['far', 'circle']} />
        </p>
        <p>New Message</p>
      </div>
      <form className="new-message-form" onSubmit={submitHandler}>
        <div>
          <label>Message</label>
          <div className="message-editor">
            <div className="message-input">
              <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={value}
                onEditorChange={(newValue) => setValue(newValue)}
                init={{
                  placeholder: 'Enter your message here',
                  height: '100%',
                  width: '100%',
                  menubar: false,
                  content_css: false,
                  inline: true,
                  plugins: ['wordcount image bbcode media lists'],
                  bbcode_dialect: 'punbb',
                  toolbar: 'undo redo | formatselect | bold italic underline | image media | bullist',
                  content_style:
                    'p { margin: 0; } img { width: 250px; } iframe { width: 250px; height: calc(250px/16*9) }',
                  image_description: false,
                  image_dimensions: false,
                  media_alt_source: false,
                  media_dimensions: false,
                  media_poster: false,
                }}
              />
            </div>
            <p className="message-input-questionmark">
              <FontAwesomeIcon icon={['far', 'question-circle']} />
            </p>
            <div className="message-input-info">{infoString}</div>
          </div>
        </div>
        <div>
          <label>URL/Path</label>
          <input
            type="text"
            value={inputUrl}
            placeholder="Enter your URL or path here"
            onChange={urlChangeHandler}
          ></input>
        </div>
        <div className="btns">
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default NewMessageForm;
