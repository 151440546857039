import _ from "lodash";
import { MANAGER } from "../actions_const";

let initState = {
  customerToken: "",
  newBalanceTransactionId: "",
  newSubscriptionId: "",
  packageInfo: {},
  subscriptionInfo: {},
  isPrepaid: false,
  prepaidInfo: {},
  isTrial: false,
  creditBalanceHistory: [],
  hasMoreCreditBalanceHistory: false,
  isLoadingPackageInfo: false,
  isLoadingCreditInfo: false,
  isLoadingSettings: false,
  processingStatus: {},
  premiumId: "",
  sender: {},
  expiryPoint: {},
  paymentInfo: {},
  couponInfo: {},
  userData: {},
  userManagement: {
    currentUserProfile: {},
    newUserProfile: {},
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case MANAGER.GET_BUSINESS_PACKAGE: {
      const { package: pk, package_info, subscription} = action.data;
      const isTrial = _.get(pk, ["auto_cancel_date"]);
      return {
        ...state,
        customerToken: _.get(subscription, ["customer", "id"]),
        packageInfo: package_info || {},
        subscriptionInfo: subscription || {},
        newSubscriptionId: _.get(subscription, ["id"]),
        isTrial,
      };
    }
    case MANAGER.SET_TRIAL_PACKAGE:
    case MANAGER.CREATE_SUBSCRIPTION: {
      const { subscription_id } = action.data;
      return {
        ...state,
        newSubscriptionId: subscription_id,
      };
    }
    case MANAGER.CANCEL_TRIAL_PACKAGE: {
      return {
        ...state,
        newSubscriptionId: "",
      };
    }
    case MANAGER.GET_PREPAID_DATA: {
      if (action.data === null) {
        return {
          ...state,
          isPrepaid: false,
          prepaidInfo: {},
          creditBalanceHistory: [],
          hasMoreCreditBalanceHistory: false
        };
      } else {
        const { credit, currency, transaction, has_more_transaction } =
          action.data;
        return {
          ...state,
          isPrepaid: true,
          prepaidInfo: {
            credit,
            currency,
          },
          creditBalanceHistory: transaction || [],
          hasMoreCreditBalanceHistory: has_more_transaction,
          newBalanceTransactionId: "",
        };
      }
    }
    case MANAGER.SET_PREPAID: {
      const { isPrepaid } = action.data;
      return {
        ...state,
        isPrepaid,
      };
    }
    case MANAGER.SET_CREDIT_BALANCE: {
      const { id } = action.data;
      return {
        ...state,
        newBalanceTransactionId: id,
      };
    }
    case MANAGER.GET_CREDIT_BALANCE_HISTORY: {
      const { transaction, has_more_transaction } = action.data;
      if (!_.isEmpty(transaction)) {
        return {
          ...state,
          hasMoreCreditBalanceHistory: has_more_transaction,
          creditBalanceHistory: [...state.creditBalanceHistory, ...transaction]
        }
      } else {
        return {
          ...state,
          hasMoreCreditBalanceHistory: has_more_transaction,
        }
      }
    }
    case MANAGER.LOADING_API: {
      const { key, value } = action.data;
      return {
        ...state,
        error: '',
        [key]: value
      }
    }
    case MANAGER.SET_PROCESSING_STATUS: {
      const { status, message } = action.data;
      return {
        ...state,
        processingStatus: {
          status,
          message
        }
      }
    }
    case MANAGER.GET_SETTINGS: {
      const { premium_id, sender, expiry_point } = action.data;
      return {
        ...state,
        premiumId: premium_id,
        sender: sender || {},
        expiryPoint: expiry_point || {}
      }
    }
    case MANAGER.SET_PREMIUM_ID: {
      const { premium_id } = action.data;
      return {
        ...state,
        premiumId: premium_id,
      }
    }
    case MANAGER.SET_SENDER_NAME: {
      const { sender_name } = action.data;
      return {
        ...state,
        sender: {
          ...state.sender,
          name: sender_name
        }
      }
    }
    case MANAGER.RESET_EXPIRY_POINT: {
      return {
        ...state,
        expiryPoint: {}
      }
    }
    case MANAGER.GET_PAYMENT_DATA: {
      const { cards, invoices } = action.data;
      return {
        ...state,
        paymentInfo: {
          cards,
          invoices
        }
      }
    }
    case MANAGER.GET_COUPON_INFO: {
      const { coupon_info, subscribed_coupon } = action.data
      return {
        ...state,
        couponInfo: {
          coupon_info,
          subscribed_coupon
        }
      }
    }
    case MANAGER.SET_USER_DATA: {
      const { idToken, clientId } = action.data
      return {
        ...state,
        userData: {
          idToken,
          clientId
        }
      }
    }
    case MANAGER.SET_MEMBER_PROFILE: {
      const { type, profile } = action.data
      return {
        ...state,
        userManagement: {
          currentUserProfile: type === 'current_profile' ? profile : state.userManagement.currentUserProfile,
          newUserProfile: type === 'new_profile' ? profile : state.userManagement.newUserProfile,
        }
      }
    }
    case MANAGER.CLEAR_MEMBER_PROFILE: {
      const { type } = action.data
      return {
        ...state,
        userManagement: {
          newUserProfile: {},
          currentUserProfile: type === 'all' ? {} : state.userManagement.currentUserProfile
        }
      }
    }
    default: {
      return state;
    }
  }
};
