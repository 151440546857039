import React from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, CardBody } from "reactstrap";
import _ from "lodash";

import ExportExamFileBtn from "./ExportExamFileBtn";
import ImportCsvFileBtn from "./ImportCsvFileBtn";
import { Title2 } from "../../components/Font";
import AdvanceOption from "./AdvanceOption";

export default function UpFileContainer({ type, message, onMessageChange, customRoute }) {
	const dictionary = useSelector((state) => state.language.dictionary);
	const store_business = useSelector((state) => state.business);
	const business = _.get(store_business, ["current"]);

	return (
		<Card>
			<CardHeader>
				<Title2 bold>
					{dictionary.addJob}{" "}
					{customRoute
						? type === "point"
							? dictionary.import_initial_point_upload_type
							: dictionary.import_initial_member_card_upload_type
						: dictionary.points}
				</Title2>
			</CardHeader>
			<CardBody>
				<div className="mb-3 d-flex justify-content-between align-items-center">
					<Title2 b>{`${dictionary.example_CSV}`}</Title2>
					<ExportExamFileBtn type={type} customRoute={customRoute} />
				</div>
				<div className="pt-3 border-top d-flex justify-content-center align-items-center">
					<ImportCsvFileBtn type={type} customRoute={customRoute} />
				</div>
				{customRoute === "/jobsMemberCard" && business.is_sender_name && <AdvanceOption message={message} onMessageChange={onMessageChange} />}
			</CardBody>
		</Card>
	);
}
