import 'react-datepicker/dist/react-datepicker.css';

import {
	Col,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap';
import { DangerBtn, InvertBtn, SuccessBtn } from '../../../components/Button';
import { Title2, Title3 } from '../../../components/Font';
import { createEcoupon, deleteEcoupon, updateEcoupon } from '../../../redux/actions/action_ecoupon';
import { enUS, th } from 'date-fns/esm/locale';
import {
	getMemberByLabel,
	getMemberByMemberCard,
	getMemberCounterBirthday,
} from '../../../redux/actions/action_member';

import BusinessNotificationSMS from '../../../components/BusinessNotificationSMS';
import ContainerModalBody from '../../../components/ContainerModalBody';
import { CouponImg } from './eCoupon.style';
import DatePicker from 'react-datepicker';
import PhotoCropper from '../../../components/PhotoCropper';
import React from 'react';
import TargetGroups from '../../../components/TargetGroups';
import { ToastBar } from '../../../components/ToastComponent/Toast.style';
import _, { isString } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { BoxInputType } from '../../ReceiptCoupon/ReceiptCouponCard.style';
import ModalConfirmCreate from './components/ModalConfirmCreate';
const MAX_INPUT = 1000;
class ECouponCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			image: '',
			title: '',
			path_location: '',
			description: '',
			action_type: 'reward',
			action_value: 0,
			send_eCoupon: false,
			expiry_type: 'expiry_date',
			ecard_life_time: '',
			start_due: new Date(),
			stop_due: new Date(),
			target_type: this.props.value?.target_type || 'all',
			target_value: this.props.value?.target_value || '',
			isOpenDel: false,
			idCard: 0,
			sendSms: true,
			tags: [],
			rfm_data: [],
			isLoadingDel: false,
			isLoading: false,
			isOpenCreate: false,
			point_start: 0,
			point_end: 0,
			credit: 0,
		};

		this.optionsProp = {};

		this.handleChange = this.handleChange.bind(this);
		this.updateEcoupon = this.updateEcoupon.bind(this);
		this.getDeleteEcoupon = this.getDeleteEcoupon.bind(this);
		this.toggleConfirmDel = this.toggleConfirmDel.bind(this);
		this.toggleSwitch = this.toggleSwitch.bind(this);
		this.onChangeDateStart = this.onChangeDateStart.bind(this);
		this.onChangeDateEnd = this.onChangeDateEnd.bind(this);
		this.toggleConfirmCreate = this.toggleConfirmCreate.bind(this);
		this.onChangeEcoupon = this.onChangeEcoupon.bind(this);
		this.onChangeEcouponType = this.onChangeEcouponType.bind(this);
		this.onChangeEcouponDate = this.onChangeEcouponDate.bind(this);
	}

	componentDidMount() {
		let { value, method } = this.props;
		let start_due = new Date();
		let stop_due = new Date();
		const isEditInCampaignCreate = method === 'Create-campaign' && !_.isEmpty(value);
		this.isAutomation();

		if (['Update', 'Copy', 'Update-campaign', 'Duplicate-campaign'].indexOf(method) > -1 || isEditInCampaignCreate) {
			if (value.ecard_life_time) {
				stop_due = moment(stop_due).add(value.ecard_life_time, 'day')._d;
			} else {
				if (value.start_due) {
					start_due = new Date(value.start_due);
				}
				if (value.stop_due) {
					stop_due = new Date(value.stop_due);
				}
			}
			let initState = {
				image: value.link_url || value.image,
				title: value.title,
				description: value.description,
				action_type: value.action_type,
				action_value: value.action_value,
				expiry_type: value.ecard_life_time ? 'ecard_life_time' : 'expiry_date',
				ecard_life_time: value.ecard_life_time,
				path_location: method === 'Update' || method === 'Update-campaign' ? value.path_location : '',
				start_due:
					method === 'Copy' && new Date().getTime() > new Date(value.start_due).getTime() && !value.ecard_life_time
						? new Date()
						: start_due,
				stop_due:
					method === 'Copy' && new Date().getTime() > new Date(value.stop_due).getTime() && !value.ecard_life_time
						? new Date()
						: stop_due,
				target_type:
					['Update', 'Copy', 'Update-campaign', 'Duplicate-campaign'].indexOf(method) > -1 || isEditInCampaignCreate
						? value.target_type
						: 'all',
				target_value:
					['Update', 'Copy', 'Update-campaign', 'Duplicate-campaign'].indexOf(method) > -1 || isEditInCampaignCreate
						? value.target_value
						: '',
				idCard: value.ecard_id,
				sendSms: value.send_sms,
			};

			this.initPropTargetGroup();
			this.setState(initState);
		}
	}

	isAutomation() {
		let { method } = this.props;

		if (method.indexOf('campaign') > -1) {
			Object.assign(this.optionsProp, {
				isAutomation: true,
				noRequest: true,
			});
		}
	}

	initPropTargetGroup() {
		let { value, method, onDuplicate } = this.props;
		let target_type = _.get(value, 'target_type', '');
		let target_value = _.get(value, 'target_value', '');
		const isCopy = method.toLowerCase() === 'copy';
		let key = '',
			targetOption = {};
		// methods that allows to request ('Update', 'Update-campaign')
		let noRequest = !isCopy || method.indexOf('campaign') > -1;
		let newValue = target_value;
		const disabled = !isCopy && method !== 'Create-campaign' && method !== 'Duplicate-campaign';
		const showValue =
			['Create-campaign', 'Update-campaign'].indexOf(method) > -1
				? true
				: method === 'Duplicate-campaign'
				? onDuplicate
				: !isCopy;

		if (['rfm_groups', 'rfm_groups_6m', 'rfm_groups_12m'].indexOf(target_type) > -1) {
			key = 'targetRFM';
			newValue = !isCopy ? { range: target_type, segment: target_value } : undefined;
		} else if (target_type === 'member_card') {
			key = 'targetMemberCard';
		} else if (target_type === 'birthmonth') {
			key = 'targetBirthMonth';
		} else if (target_type === 'member_purchase_product') {
			key = 'targetPurchaseProduct';
			newValue = newValue && isString(newValue) ? JSON.parse(newValue) : newValue;
			targetOption = {
				noDateSelect: method.indexOf('campaign') > -1 ? method === 'Update-campaign' : !isCopy,
				excludeArchive: !showValue, // false for show archive product
			};
		} else if (target_type === 'member_receive_reward') {
			key = 'targetReward';
			newValue = !isCopy ? (isString(newValue) ? JSON.parse(newValue) : newValue) : undefined;
		} else if (target_type === 'member') {
			key = 'targetSpecificMember';
			newValue =
				newValue &&
				(isString(newValue) ? newValue.split(',') : Array.isArray(newValue) ? newValue : []).map((item) => {
					return {
						value: item,
						member_badge_code: item,
						isValid: true,
					};
				});
			targetOption = {
				method: method === 'Update' && this.props.campaign_title ? 'Update-campaign' : method,
				hideTagInput: method.indexOf('campaign') > -1 ? method === 'Update-campaign' : !isCopy, // hideTagInput on showValue must be false
			};
		} else if (target_type === 'member_label') {
			key = 'targetMemberLabel';
			targetOption = {
				readonly: method.indexOf('campaign') > -1 ? method === 'Update-campaign' : !isCopy,
				disabledLabel: false,
				hideAddButton: method.indexOf('campaign') > -1 ? method === 'Update-campaign' : !isCopy,
			};
		} else if (target_type === 'member_points') {
			key = 'targetMemberPoints';
			newValue = newValue && isString(newValue) ? JSON.parse(newValue) : newValue;
		} else if (target_type === 'member_pending_group') {
			key = 'targetPreMember';
		} else {
			key = 'targetAllMember';
		}

		if (!showValue) newValue = undefined;

		Object.assign(this.optionsProp, {
			noRequest: noRequest,
			disabled: disabled,
			value: newValue,
			[key]: targetOption,
		});
	}

	toggleConfirmDel() {
		this.setState({ isOpenDel: !this.state.isOpenDel });
	}

	toggleConfirmCreate() {
		this.setState({ isOpenCreate: !this.state.isOpenCreate });
	}

	getDeleteEcoupon() {
		const {
			store_business,
			store_language: { dictionary },
		} = this.props;

		this.setState({ isLoadingDel: true }, () => {
			this.props.deleteEcoupon(store_business.current.business_code, this.state.idCard, (err) => {
				this.setState({ isLoadingDel: false });
				this.props.toggle();
				this.props.getEcoupon();
				if (Boolean(err)) this.toastShow('error', dictionary.error_occurred);
				else this.toastShow('success', dictionary.deleted_coupon_successfully);
			});
		});
	}

	onChangeEcoupon(e) {
		let min = 0,
			max = 365;
		let { name, value } = e.target;

		if (name === 'ecard_life_time') {
			value = value ? parseInt(value, 10) : min;
			value = value < min ? min : value > max ? max : value;
		}

		this.setState({ [name]: value });
	}

	onChangeEcouponType(value) {
		this.setState({ expiry_type: value });
	}

	onChangeEcouponDate(name, value) {
		if (!value) value = new Date();
		if (name === 'start_due' && value > this.state.stop_due) this.setState({ [name]: value, stop_due: value });
		else if (name === 'stop_due' && value < this.state.start_due) this.setState({ [name]: this.state.start_due });
		else this.setState({ [name]: value });
	}

	handleChange(event) {
		let { value, name } = event.target;
		if (name === 'action_value' && !value.match(/^[0-9]*$/g)) return null;
		if (name === 'title' && value.length > 50) return;

		this.setState({ [name]: value });
	}

	getImageCrop(data) {
		this.setState({ image: data });
	}

	setCredit(data) {
		this.setState({ credit: data });
	}

	toggleSwitch() {
		this.setState({ sendSms: !this.state.sendSms });
	}

	toastShow(type, message) {
		toast[type](
			<ToastBar>
				<Title2
					bold
					white
					style={{ width: '100%', textAlign: 'center' }}>
					{message}
				</Title2>
			</ToastBar>
		);
	}

	updateEcoupon() {
		let {
			method,
			store_business,
			store_broadcast,
			store_language: { dictionary, locale },
			onApply,
		} = this.props;
		const broadcastTarget = store_broadcast.broadcast_target;
		let {
			image,
			title,
			description,
			send_eCoupon,
			action_type,
			action_value,
			start_due,
			stop_due,
			sendSms,
			path_location,
			expiry_type,
			ecard_life_time,
		} = this.state;
		let data_eCoupon = {
			image: image,
			title: title,
			description: description,
			action_type: action_type,
			action_value: action_value,
			send_eCoupon: send_eCoupon,
			target_type: broadcastTarget.target,
			target_value: broadcastTarget.target_data,
			provider: 'system',
			send_sms: sendSms,
			path_location: path_location,
			copy: false,
			language: locale,
			credit: this.state.credit,
		};

		if (expiry_type === 'expiry_date') {
			start_due = moment(start_due).startOf('day');
			stop_due = moment(stop_due).endOf('day');
			data_eCoupon = Object.assign({}, data_eCoupon, {
				start_due,
				stop_due,
				ecard_life_time: null,
			});
		} else {
			data_eCoupon = Object.assign({}, data_eCoupon, {
				ecard_life_time,
				start_due: null,
				stop_due: null,
			});
		}

		if (['Create', 'Copy'].indexOf(method) > -1) {
			data_eCoupon.copy = this.state.image.includes('https://');
			if (data_eCoupon.copy) data_eCoupon.path_location = this.props.value.path_location;
			this.setState({ isLoading: true }, () => {
				this.props.createEcoupon(store_business.current.business_code, data_eCoupon, (err) => {
					this.setState({ isLoading: false });
					this.props.toggle();
					this.props.getEcoupon();
					if (Boolean(err)) {
						if (err.error && err.error.code === 'pointspot/credit-not-enough')
							this.toastShow('error', dictionary.credit_not_enough);
						else this.toastShow('error', dictionary.error_occurred);
					} else this.toastShow('success', dictionary.create_coupon_successfully);
				});
			});
		} else if (method === 'Create-campaign' || method === 'Update-campaign' || method === 'Duplicate-campaign') {
			data_eCoupon.path_location = this.state.image.includes('https://') ? '' : this.props.value.path_location;
			if (method === 'Update-campaign') {
				data_eCoupon.ecard_id = this.props.value.ecard_id;
			}

			if (typeof onApply === 'function') {
				onApply(data_eCoupon);
			}
		} else {
			data_eCoupon.ecard_id = this.props.value.ecard_id;
			data_eCoupon.path_location = this.state.image.includes('https://') ? '' : this.props.value.path_location;
			this.setState({ isLoading: true }, () => {
				this.props.updateEcoupon(store_business.current.business_code, data_eCoupon, (err) => {
					this.setState({ isLoading: false });
					this.props.toggle();
					this.props.getEcoupon();
					if (Boolean(err)) this.toastShow('error', dictionary.error_occurred);
					else this.toastShow('success', dictionary.edited_coupon_successfully);
				});
			});
		}
	}

	verifyFields() {
		let { method, store_broadcast } = this.props;
		let _thisDay = new Date().setHours(0, 0, 0, 0) - 1;
		const { image, title, description, start_due, stop_due, action_type, expiry_type, ecard_life_time } = this.state;
		let result = !image || !title || !description || !action_type || !start_due || !stop_due;
		const broadcastTarget = store_broadcast.broadcast_target;

		if (!result && method !== 'Update' && method !== 'Update-campaign') {
			if (['birthmonth', 'rfm_groups', 'rfm_groups_6m', 'rfm_groups_12m'].indexOf(broadcastTarget.target) > -1) {
				result = Boolean(result || !(broadcastTarget.target_data > 0));
			}
			if (['member_card', 'member_label', 'member_points'].indexOf(broadcastTarget.target) > -1) {
				result = Boolean(result || _.isEmpty(broadcastTarget.target_data));
			}
			if (broadcastTarget.target === 'member') {
				let data = broadcastTarget.target_data || '';
				result = Boolean(
					result || _.isEmpty(broadcastTarget.target_data) || (data.split(',').length > 0 && data.split(',').length > 1000)
				);
			}
			if (broadcastTarget.target === 'member_purchase_product') {
				result = Boolean(result || _.isEmpty(_.get(broadcastTarget, 'target_data.products', [])));
			}
		}

		if (expiry_type === 'ecard_life_time') {
			result = Boolean(
				result || !(ecard_life_time && parseInt(ecard_life_time) > 0 && parseInt(ecard_life_time) < 366)
			);
		} else {
			result = Boolean(
				result ||
					!((new Date(start_due).getTime() > _thisDay || method.indexOf('Update') > -1) && start_due <= stop_due)
			);
		}

		return result;
	}

	onChangeDateStart(date) {
		if (!date) date = new Date();
		this.setState({
			start_due: date,
			stop_due: this.state.stop_due < date ? date : this.state.stop_due,
		});
	}

	onChangeDateEnd(date) {
		if (!date) date = new Date();
		if (date < this.state.start_due) this.setState({ stop_due: this.state.start_due });
		else this.setState({ stop_due: date });
	}

	onChangeRaw = ({ target: { name } }) => {
		if (name === 'start_due') return this.onChangeDateStart(new Date());

		if (name === 'stop_due') return this.onChangeDateEnd(new Date());
	};

	handleTarget = (data) => {
		let { target, value, rfm_data, tags } = data;

		if (rfm_data) this.setState({ rfm_data: rfm_data });
		if (tags) this.setState({ tags: tags });

		this.setState({ target_type: target, target_value: value });

		if (target === 'member_points') {
			this.setState({
				point_start: data.point_start,
				point_end: data.point_end,
			});
		}
	};

	render() {
		let { method } = this.props;
		let {
			store_packages: { packages },
		} = this.props;
		let {
			store_language: { dictionary },
		} = this.props;
		let { isLoading, isLoadingDel, expiry_type, ecard_life_time } = this.state;
		let isPackageStatusHold = packages && packages.subscription_status === 'hold';

		const option_typeCoupon = [
			{ name: dictionary.ecoupon_free, value: 'reward' },
			{ name: dictionary.ecoupon_discount, value: 'discount' },
		];

		const option_expiryDate = [
			{ value: 'expiry_date', name: dictionary.schedule_of_usage },
			{ value: 'ecard_life_time', name: dictionary.set_expired_date_after_receiving_coupon },
		];

		return (
			<div>
				<Modal
					fade
					centered
					size="lg"
					isOpen={this.props.isOpen}>
					<ModalBody>
						<ContainerModalBody>
							<Row className="px-2">
								<Col
									xs={12}
									md={12}
									lg={6}
									xl={6}>
									<FormGroup>
										<Title2
											className="pb-2"
											bold>
											<span className="text-danger">*</span> {dictionary.ecoupon_image}{' '}
										</Title2>
										<CouponImg>
											<PhotoCropper
												disabled={isLoading || isLoadingDel}
												crop={this.getImageCrop.bind(this)}
												aspectRatio={2 / 1}
												image={this.state.image}
											/>
										</CouponImg>
									</FormGroup>
									<FormGroup>
										<div className="w-100 d-flex align-items-center justify-content-between">
											<Title2
												className="pb-2"
												bold>
												<span className="text-danger">*</span> {dictionary.name_ecoupon}{' '}
											</Title2>
											<Title3 secondary>{`(${this.state.title.length || 0} / 50)`}</Title3>
										</div>
										<Input
											disabled={isLoading || isLoadingDel}
											type="text"
											name="title"
											value={this.state.title}
											placeholder={dictionary.name_ecoupon_placeholder}
											onChange={this.handleChange}
										/>
									</FormGroup>
									<FormGroup>
										<Title2
											className="pb-2"
											bold>
											{' '}
											<span className="text-danger">*</span> {dictionary.type_ecoupon}{' '}
										</Title2>
										<Input
											type="select"
											name="action_type"
											value={this.state.action_type}
											onChange={this.handleChange}
											style={{ fontSize: '14px' }}
											disabled={method === 'Update' || method === 'Update-campaign' || isLoading || isLoadingDel}>
											{option_typeCoupon.map((value, key) => (
												<option
													key={key}
													value={value.value}>
													{value.name}
												</option>
											))}
										</Input>
									</FormGroup>
								</Col>
								<Col
									xs={12}
									md={12}
									lg={6}
									xl={6}>
									<FormGroup>
										<Title2
											className="pb-2"
											bold>
											{' '}
											<span className="text-danger">*</span> {dictionary.description_ecoupon}{' '}
										</Title2>
										<Input
											disabled={isLoading || isLoadingDel}
											rows={4}
											type="textarea"
											name="description"
											value={this.state.description}
											onChange={this.handleChange}
											placeholder={dictionary.description_ecoupon}
										/>
									</FormGroup>
									<FormGroup hidden={this.state.action_type !== 'points_in'}>
										<Title2 bold>
											{' '}
											<span className="text-danger">*</span> {dictionary.points}{' '}
										</Title2>
										<Input
											type="text"
											placeholder="0"
											name="action_value"
											value={this.state.action_value}
											onChange={this.handleChange}
											disabled={method === 'Update' || method === 'Update-campaign' || isLoading || isLoadingDel}
										/>
									</FormGroup>
									<FormGroup>
										<Title2
											className="pb-2"
											bold>
											<span className="text-danger">*</span> {dictionary.duration_usage}{' '}
										</Title2>
										<div className="pl-3">
											{option_expiryDate.map((value, key) => {
												return (
													<BoxInputType
														key={key}
														onClick={() => {
															!isLoading && this.onChangeEcouponType(value.value);
														}}>
														<input
															type="radio"
															value={expiry_type}
															name="expiry_type"
															disabled={isLoading}
															checked={expiry_type === value.value}
															onChange={() => {
																this.onChangeEcouponType(value.value);
															}}
														/>
														<Title2
															bold
															className="pl-2">
															{value.name}
														</Title2>
													</BoxInputType>
												);
											})}
										</div>
									</FormGroup>
									{expiry_type === 'expiry_date' && (
										<div className="align-items-center d-flex">
											<FormGroup>
												<Title2
													className="pb-2"
													bold>
													<span className="text-danger">*</span> {dictionary.start_date}{' '}
												</Title2>
												<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
													<DatePicker
														name="start_due"
														disabled={isLoading || isLoadingDel}
														fixedHeight
														selectsStart
														showYearDropdown
														showMonthDropdown
														locale={dictionary.locale === 'en' ? enUS : th}
														minDate={new Date()}
														className="form-control"
														dateFormat="dd MMM yyyy"
														popperPlacement="top-start"
														startDate={new Date(this.state.start_due)}
														selected={new Date(this.state.start_due)}
														endDate={new Date(this.state.stop_due)}
														onChangeRaw={this.onChangeRaw}
														onChange={(date) => {
															this.onChangeDateStart(date);
														}}
													/>
												</div>
											</FormGroup>
											<Title2 className="pt-2 px-3">-</Title2>
											<FormGroup>
												<Title2
													className="pb-2"
													bold>
													<span className="text-danger">*</span> {dictionary.end_date}{' '}
												</Title2>
												<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
													<DatePicker
														name="stop_due"
														disabled={isLoading || isLoadingDel}
														selectsEnd
														fixedHeight
														showYearDropdown
														showMonthDropdown
														locale={dictionary.locale === 'en' ? enUS : th}
														dateFormat="dd MMM yyyy"
														className="form-control"
														popperPlacement="top-end"
														popperClassName="endDatePicker"
														startDate={new Date(this.state.start_due)}
														endDate={new Date(this.state.stop_due)}
														selected={new Date(this.state.stop_due)}
														minDate={new Date(this.state.start_due)}
														onChangeRaw={this.onChangeRaw}
														onChange={(date) => {
															this.onChangeDateEnd(date);
														}}
													/>
												</div>
											</FormGroup>
										</div>
									)}
									{expiry_type === 'ecard_life_time' && (
										<FormGroup>
											<Title2
												className="pb-2"
												bold>
												<span className="text-danger">*</span> {dictionary.after_receiving_coupon}{' '}
											</Title2>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<Title3>
															<i className="fas fa-history" />
														</Title3>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													type="tel"
													disabled={isLoading}
													name="ecard_life_time"
													style={{ textAlign: 'end' }}
													value={ecard_life_time || ''}
													onChange={this.onChangeEcoupon}
													placeholder="3"
												/>
												<InputGroupAddon addonType="append">
													<InputGroupText>
														<Title3
															bold
															className="px-2">
															{dictionary.day}
														</Title3>
													</InputGroupText>
												</InputGroupAddon>
											</InputGroup>
										</FormGroup>
									)}

									<TargetGroups
										target={this.state.target_type}
										noRequest={_.get(this.optionsProp, 'noRequest', false)}
										options={this.optionsProp}
										disabled={method === 'Update' || method === 'Update-campaign' || isLoading || isLoadingDel}
										max={MAX_INPUT}
									/>
									<FormGroup>
										<div className="d-flex">
											<div>
												<input
													type="checkbox"
													disabled={method === 'Update' || method === 'Update-campaign' || isLoading || isLoadingDel}
													checked={this.state.sendSms}
													onClick={this.toggleSwitch.bind(this)}
													onChange={this.toggleSwitch.bind(this)}
												/>
											</div>
											<div
												className="pl-2"
												style={{ cursor: 'pointer' }}
												onClick={
													method === 'Update' || method === 'Update-campaign' || isLoading || isLoadingDel
														? null
														: this.toggleSwitch.bind(this)
												}>
												<Title2 bold> {dictionary.coupon_send_sms_text} </Title2>
												<BusinessNotificationSMS secondary />
											</div>
										</div>
									</FormGroup>
								</Col>
								<Col
									xs={12}
									className="p-0">
									<div className="w-100 d-flex align-items-center justify-content-center">
										{this.props.campaign_title && method.indexOf('Update') > -1 ? (
											<Title3
												bold
												danger
												className="text-center"
												style={{ whiteSpace: 'pre-line' }}>
												{String.format(dictionary.campaign_edit_coupon_warning, this.props.campaign_title)}
											</Title3>
										) : (
											<Title3
												bold
												danger
												className="text-center">
												{dictionary.ecoupon_will_incur_costs}
											</Title3>
										)}
									</div>
								</Col>
							</Row>
						</ContainerModalBody>
					</ModalBody>
					<ModalFooter>
						<div className="w-100 d-flex align-items-center justify-content-between">
							<div>
								{method === 'Update' && !this.props.campaign_title && (
									<DangerBtn
										md
										bold
										onClick={this.toggleConfirmDel}
										disabled={isLoading || isLoadingDel}>
										{dictionary.delete}
									</DangerBtn>
								)}
							</div>
							<div className="d-flex align-items-center justify-content-end">
								<InvertBtn
									md
									bold
									onClick={this.props.toggle}>
									{' '}
									{dictionary.cancel}{' '}
								</InvertBtn>
								{isLoading ? (
									<SuccessBtn
										md
										bold
										disabled>
										<i className="fas fa-spinner fa-pulse" />
									</SuccessBtn>
								) : isPackageStatusHold ? (
									<DangerBtn
										md
										bold
										fluid
										disabled>
										{dictionary.not_do_transaction}
									</DangerBtn>
								) : ['Create', 'Copy'].indexOf(method) > -1 ? (
									<SuccessBtn
										md
										bold
										disabled={this.verifyFields() || isLoadingDel}
										onClick={this.toggleConfirmCreate}>
										{dictionary.send}
									</SuccessBtn>
								) : (
									<SuccessBtn
										md
										bold
										disabled={this.verifyFields() || isLoadingDel}
										onClick={this.updateEcoupon}>
										{method === 'Create-campaign' ? dictionary.apply : dictionary.save}
									</SuccessBtn>
								)}
							</div>
						</div>
					</ModalFooter>
				</Modal>

				<ModalFormDeleteEcoupon
					dictionary={dictionary}
					isLoadingDel={isLoadingDel}
					isOpen={this.state.isOpenDel}
					toggleConfirmDel={this.toggleConfirmDel}
					onClickDeleteEcoupon={this.getDeleteEcoupon}
				/>

				{this.state.isOpenCreate && (
					<ModalConfirmCreate
						{...this.state}
						{...this.props}
						method={method}
						isOpen={this.state.isOpenCreate}
						updateEcoupon={this.updateEcoupon}
						toggleModal={this.toggleConfirmCreate}
						isLoading={this.state.isLoading}
						setCredit={(data) => this.setCredit(data)}
					/>
				)}
			</div>
		);
	}
}

const ModalFormDeleteEcoupon = (props) => {
	let { dictionary, isOpen, toggleConfirmDel, onClickDeleteEcoupon, isLoadingDel } = props;
	return (
		<Modal
			fade
			centered
			isOpen={isOpen}>
			<ModalHeader>
				<Title2 bold>{dictionary.notification}</Title2>
			</ModalHeader>
			<ModalBody className="text-center">
				<div
					className="d-flex align-items-center justify-content-center"
					style={{ minHeight: 150 }}>
					<Title2 bold>{dictionary.confirm_delete_coupon}</Title2>
				</div>
			</ModalBody>
			<ModalFooter>
				<div className="d-flex justify-content-end">
					<InvertBtn
						md
						bold
						onClick={toggleConfirmDel}>
						{dictionary.close}
					</InvertBtn>
					<DangerBtn
						md
						bold
						id={`btn-delete`}
						onClick={onClickDeleteEcoupon}
						disabled={isLoadingDel}>
						{isLoadingDel ? <i className="fas fa-spinner fa-pulse" /> : dictionary.delete}
					</DangerBtn>
				</div>
			</ModalFooter>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		store_business: state.business,
		store_language: state.language,
		store_ecoupon: state.ecoupon,
		store_packages: state.packages,
		store_broadcast: state.broadcast,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return bindActionCreators(
		{
			createEcoupon,
			updateEcoupon,
			deleteEcoupon,
			getMemberCounterBirthday,
			getMemberByMemberCard,
			getMemberByLabel,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ECouponCard);
